<template>
  <section v-if="information">
    <b-overlay :show="loading" rounded="sm">
        
        <div class="box-content">
          <div
            v-bind:class="authenticated"
          >
            <div v-if="error" class="text-center">
              <i class="fal fa-exclamation-circle"></i>
              {{ $t("ERROR.COULD_NOT_USE_LINK") }}
              <div class="mt-4">
                <b-btn variant="primary" :to="{ name: 'Login' }">{{
                  $t("BACK")
                }}</b-btn>
              </div>
            </div>
            <div v-if="!error">
              <div v-if="information.message">
                <LinkResponseMessage
                  v-if="information.message.message_type == 'link-response'"
                  :link_uuid="link_uuid"
                  :authenticated="authenticated"
                  :information="information"
                  :loading="loading"
                ></LinkResponseMessage> 
                <LinkMessage
                  v-if="regularMessage"
                  :link_uuid="link_uuid"
                  :authenticated="authenticated"
                  :information="information"
                  :loading="loading"
                ></LinkMessage>
                <LinkTeamsMeeting
                  v-if="information.message.message_type == 'teams-meeting'"
                  :link_uuid="link_uuid"
                  :authenticated="authenticated"
                  :information="information"
                  :loading="loading"
                ></LinkTeamsMeeting>
                <LinkNuiteqMeeting
                  v-if="information.message.message_type == 'nuiteq-meeting'"
                  :link_uuid="link_uuid"
                  :authenticated="authenticated"
                  :information="information"
                  :loading="loading"
                ></LinkNuiteqMeeting>
                <LinkJitsiMeeting
                  v-if="information.message.message_type == 'jitsi-meeting'"
                  :link_uuid="link_uuid"
                  :authenticated="authenticated"
                  :information="information"
                  :loading="loading"
                ></LinkJitsiMeeting>
                <LinkJitsiSecureMeeting
                  v-if="information.message.message_type == 'jitsi-secure-meeting'"
                  :link_uuid="link_uuid"
                  :authenticated="authenticated"
                  :information="information"
                  :loading="loading"
                ></LinkJitsiSecureMeeting>
                <LinkInformation
                  v-if="information.message.message_type"
                  :information="information"
                  :loading="loading"
                ></LinkInformation>
              </div>
            </div>
          </div>
        </div>

    </b-overlay>
   </section>
</template>
<script>
import LinkResponseMessage from "@/components/Link/Information/ResponseMessage";
import LinkMessage from "@/components/Link/Information/RegularMessage";
import LinkNuiteqMeeting from "@/components/Link/Information/NuiteqMeeting";
import LinkTeamsMeeting from "@/components/Link/Information/TeamsMeeting";
import LinkJitsiMeeting from "@/components/Link/Information/JitsiMeeting";
import LinkJitsiSecureMeeting from "@/components/Link/Information/JitsiSecureMeeting";
import LinkInformation from "@/components/Link/Information";
export default {
  components: {
    LinkResponseMessage,
    LinkNuiteqMeeting,
    LinkInformation,
    LinkJitsiMeeting,
    LinkMessage,
    LinkTeamsMeeting,
    LinkJitsiSecureMeeting,
  },
  props: ["link_uuid", "message_uuid"],
  data() {
    return {
      loading: false,
      errorMessage: "",
      error: false,
      authenticated: true,
      information: {
        message: null
      },
    };
  },
  methods: {
    getInformation: function() {
      this.loading = true;
      let self = this;
      this.$http
        .post(this.user.hostname + "/message/link/conversation/information",
        {
          link_uuid: this.link_uuid,
          message_uuid: this.message_uuid
        })
        .then(function(response) {
          self.loading = false;
          self.information.message = response.data;
        })
        .catch((error) => {
          self.error = true;
          self.loading = false;
          if (error.response.data != undefined) {
            self.error = true;
            self.errorMessage = error.response.data;
          }
        });
    },
  },
  computed: {
    regularMessage(){
      return this.information.message.message_type == 'message' || this.information.message.message_type == 'creator-link-response' || this.information.message.message_type == 'response';
    },
    createdTime() {
      return this.FormatDateTime(this.information.created_date);
    },
    createdName() {
      return (
        this.information.message.creator.name +
        ", " +
        this.information.message.creator.organisation_name
      );
    }
  },
  mounted: function() {
    this.getInformation();
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
 {
  opacity: 0;
}
</style>