var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.information
    ? _c(
        "section",
        [
          _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
            _c("div", { staticClass: "box-content" }, [
              _c("div", { class: _vm.authenticated }, [
                _vm.error
                  ? _c("div", { staticClass: "text-center" }, [
                      _c("i", { staticClass: "fal fa-exclamation-circle" }),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("ERROR.COULD_NOT_USE_LINK")) +
                          "\n            "
                      ),
                      _c(
                        "div",
                        { staticClass: "mt-4" },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                variant: "primary",
                                to: { name: "Login" },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("BACK")))]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                !_vm.error
                  ? _c("div", [
                      _vm.information.message
                        ? _c(
                            "div",
                            [
                              _vm.information.message.message_type ==
                              "link-response"
                                ? _c("LinkResponseMessage", {
                                    attrs: {
                                      link_uuid: _vm.link_uuid,
                                      authenticated: _vm.authenticated,
                                      information: _vm.information,
                                      loading: _vm.loading,
                                    },
                                  })
                                : _vm._e(),
                              _vm.regularMessage
                                ? _c("LinkMessage", {
                                    attrs: {
                                      link_uuid: _vm.link_uuid,
                                      authenticated: _vm.authenticated,
                                      information: _vm.information,
                                      loading: _vm.loading,
                                    },
                                  })
                                : _vm._e(),
                              _vm.information.message.message_type ==
                              "teams-meeting"
                                ? _c("LinkTeamsMeeting", {
                                    attrs: {
                                      link_uuid: _vm.link_uuid,
                                      authenticated: _vm.authenticated,
                                      information: _vm.information,
                                      loading: _vm.loading,
                                    },
                                  })
                                : _vm._e(),
                              _vm.information.message.message_type ==
                              "nuiteq-meeting"
                                ? _c("LinkNuiteqMeeting", {
                                    attrs: {
                                      link_uuid: _vm.link_uuid,
                                      authenticated: _vm.authenticated,
                                      information: _vm.information,
                                      loading: _vm.loading,
                                    },
                                  })
                                : _vm._e(),
                              _vm.information.message.message_type ==
                              "jitsi-meeting"
                                ? _c("LinkJitsiMeeting", {
                                    attrs: {
                                      link_uuid: _vm.link_uuid,
                                      authenticated: _vm.authenticated,
                                      information: _vm.information,
                                      loading: _vm.loading,
                                    },
                                  })
                                : _vm._e(),
                              _vm.information.message.message_type ==
                              "jitsi-secure-meeting"
                                ? _c("LinkJitsiSecureMeeting", {
                                    attrs: {
                                      link_uuid: _vm.link_uuid,
                                      authenticated: _vm.authenticated,
                                      information: _vm.information,
                                      loading: _vm.loading,
                                    },
                                  })
                                : _vm._e(),
                              _vm.information.message.message_type
                                ? _c("LinkInformation", {
                                    attrs: {
                                      information: _vm.information,
                                      loading: _vm.loading,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }