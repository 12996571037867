<template>
  <section>
    <div v-if="options.length != 0">
      <div v-if="!loading">
        <div v-if="siths_info.net_id_enterprise">
          <NetIDEnterprise
            v-if="enterprise"
            @setaccess="setaccess"
            @authenticated="authenticated"
            :link_uuid="link_uuid"
          ></NetIDEnterprise>
        </div>

        <div v-if="siths_info.net_id_access && siths_info.net_id_enterprise">
          <p class="mt-2 mb-2 strikethrough" v-if="access">
            <span>{{ $t("AUTH_METHODS.DO_YOU_HAVE_NET_ID_ACCESS") }}</span>
          </p>
        </div>
      </div>

      <div v-if="siths_info.net_id_access">
        <NetIDAccess
          @loading="setLoading"
          v-if="access"
          @setenterprise="setenterprise"
          @authenticated="authenticated"
          :link_uuid="link_uuid"
        ></NetIDAccess>
      </div>
    </div>
    <div v-if="options.length == 0">
      <b-overlay :show="redirect" rounded="sm">
        <button class="btn btn-fill  btn-block btn-primary" @click="loginSubmit">
          {{ $t("START_IDENTIFICATION") }}
        </button>
      </b-overlay>
    </div>
  </section>
</template>
<script>
import NetIDAccess from "@/components/Link/Auth/Siths/NetIDAccess.vue";
import NetIDEnterprise from "@/components/Link/Auth/Siths/NetIDEnterprise.vue";
export default {
  props: ["link_uuid", "siths_info"],
  components: { NetIDAccess, NetIDEnterprise },
  data() {
    return {
      loading: false,
      access: true,
      enterprise: true,
      auth_uuid: "",
      errorMessage: "",
      options: [],
      redirect: false,
    };
  },
  methods: {
    popuplateMethods() {
      for (let ix = 0; ix < this.methods.length; ix++) {
        let method = this.methods[ix];
        if(method.auth_type == "siths-card")
        {
          if(method.childs.length > 1)
          {
            this.options.push({
                value: null,
                text: this.$t("AUTH_METHODS.SELECT_VERIFICATION_METHOD"),
              });
          }
          for (let ix = 0; ix < method.childs.length; ix++) {
            let childMethod = method.childs[ix];
            if(method.childs.length == 1)
            {
              this.authentication_method = childMethod.auth_type;
            }
            if (childMethod.auth_method != "") {
              this.options.push({
                value: childMethod.auth_method,
                text: this.$t("AUTH_METHODS." + childMethod.auth_method),
              });
            }
          }
        }
      }
    },
    loginSubmit: function() {
      this.loading = true;
      let self = this;
      if (this.email !== "") {
        self.$emit("loading", true);
        this.$http
          .post(this.user.hostname + "/message/link/authenticate", {
            link_uuid: this.link_uuid,
            method: "",
          })
          .then(function(result) {
            self.loading = false;
            self.redirect = true;
            window.location.href = result.data.request_url;
          })
          .catch(function() {
            self.$emit("loading", false);
            self.loading = false;
          });
      } else {
        self.$emit("loading", false);
      }
    },
    setLoading(value) {
      this.loading = value;
      this.$emit("loading", value);
    },
    authenticated(link_token) {
      this.$emit("authenticated", link_token);
    },
    setaccess(val) {
      this.access = val;
    },
    setenterprise(val) {
      this.enterprise = val;
    },
    async getMethods() {
      let self = this;
      await this.$http
        .get(this.user.hostname + "/authenticate/external/methods")
        .then(function(result) {
          self.methods = result.data;
          self.popuplateMethods();
        })
        .catch(function() {
          self.loading = false;
        });
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted: function() {
    this.loading = false;
    this.getMethods();
  },
};
</script>
<style></style>
