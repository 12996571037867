<template>
  <section>
    <b-overlay :show="loading" rounded="sm">
      <div v-if="!verifying">
        <button
          v-on:click="loginSubmit"
          class="btn btn-block btn-fill btn-primary"
        >
          {{ $t("START_IDENTIFICATION") }}
        </button>
      </div>
    </b-overlay>
  </section>
</template>
<script>
export default {
  props: ["link_uuid"],
  data() {
    return {
      verifying: false,
      loading: true,
      statusMessage: "",
      code: "",
      auth_uuid: "",
      hintMessage: "",
      errorMessage: "",
    };
  },
  methods: {
    async loginSubmit(){
      await this.$store.dispatch("user/setRedirectUrl", window.location.href);        
      this.$router.push({ name: 'Login' }).catch(() => {});
    },
    async checkAuthLink() {
      let self = this;
      self.loading = true;
      await this.$http
        .get(this.user.hostname + "/sefos-account/link/" + this.link_uuid)
        .then(async function(result) {
          if(result.data.message == "authenticated")
          {
            self.$emit("authenticated", result.data.token);
          }else{
            self.loading = false;
          }
        })
        .catch(function() {
          self.loading = false;
        });
    },
    async checkAuthenticated() {
      let self = this;
      self.loading = true;
      await this.$http
        .get(this.user.hostname + "/isLoggedin")
        .then(async function(result) {
          if(result.data.user != undefined && result.data.user != null)
          {
            self.checkAuthLink();
          }
        })
        .catch(function() {
          self.loading = false;
        });
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted: function() {  
    this.$emit("loading", false);
    this.loading = false; 
    this.checkAuthLink();
  },
};
</script>
<style></style>
