<template>
  <section>
    <LinkView @showConversation="showConversation" v-if="!conversation" :verify="verify" :link_uuid="link_uuid" :auth_uuid="auth_uuid"></LinkView>
    <LinkConversation @closeConversation="closeConversation"  v-if="conversation" :link_uuid="link_uuid"></LinkConversation>
  </section>
</template>
<script>
import LinkView from "@/components/Link/View";
import LinkConversation from "@/components/Link/Conversation";
export default {
  components: {
    LinkView,
    LinkConversation
  },
  props: ["verify", "link_uuid", "auth_uuid"],
  data() {
    return {
      conversation: false 
    };
  },
  methods: {
    showConversation()
    {
      this.conversation = true;
    },
    closeConversation()
    {
      this.conversation = false;
    }
  },
  computed: {
  },
  mounted: function() {
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
 {
  opacity: 0;
}
</style>
