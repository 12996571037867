<template>
  <section>
    <div v-if="!loading">
      <b-overlay :show="redirect" rounded="sm">
        <button v-on:click="loginSubmit" class="btn btn-fill btn-block btn-primary">
          {{ $t("START_IDENTIFICATION") }}
        </button>
      </b-overlay>
    </div>
    <div class="text-center" v-if="authRef">
      <div v-if="!showOnThisDevice" class="mb-2">
        <b-img :src="frejaQrCode" :alt="$t('AUTH_METHODS.freja') + ' QR'"></b-img>
        <div v-if="hintMessage != 'outstandingTransaction'">
          <h5>{{ statusMessage }}</h5>
        </div>
        <a variant="primary" :href="urlDevice">{{ $t("SAME_DEVICE") }}</a>
      </div>
      <div class="mt-2 mb-2" v-if="showOnThisDevice">
        <a variant="primary" class="btn btn-primary btn-block btn-fill text-white" :href="urlDevice">{{ $t("SAME_DEVICE") }}</a>
        <br />
        <br />
        <a href="#" @click.prevent="otherDevice"> {{ $t("OTHER_DEVICE") }}</a>
      </div>
      <button v-on:click="cancelSubmit" class="mt-2 btn btn-block btn-secondary">
        {{ $t("CANCEL") }}
      </button>
    </div>
  </section>
</template>
<script>
import mobileDetect from "mobile-device-detect";
export default {
  props: ["link_uuid"],
  data() {
    return {
      redirect: false,
      device: mobileDetect,
      loading: true,
      frejaQrCode: "",
      authRef: "",
      auth_uuid: "",
      statusMessage: "",
      timer: "",
      hintMessage: "",
      errorMessage: "",
      showOnThisDevice: false,
    };
  },
  methods: {
    otherDevice: function() {
      this.showOnThisDevice = false;
    },
    reset: function() {
      clearTimeout(this.timer);
      this.loading = false;
      this.showOnThisDevice = this.device.isMobile;
      this.authRef = "";
      this.$emit("loading", false);
    },
    cancelSubmit: function() {
      this.reset();
      this.$http.get(this.user.hostname + "/authenticate/cancel/" + this.auth_uuid);
    },
    loginSubmit: function() {
      this.loading = true;
      let self = this;
      self.$emit("loading", true);
      this.$http
        .post(this.user.hostname + "/message/link/authenticate", {
          link_uuid: this.link_uuid,
          method: "freja",
        })
        .then(function(result) {
          if(result.data.redirect == 1)
          {
             self.loading = false;
             self.redirect = true;
             window.location.href = result.data.request_url;
          }else{
            self.statusMessage = "";
            self.auth_uuid = result.data.auth_uuid;
            if(result.data.authRef != undefined)
            {   
              self.authRef = result.data.authRef;
            }else{
              self.authRef = result.data.identifier;
            }
            self.bindUserToTransaction();
          }
        })
        .catch(function() {
          self.reset();
        });
    },
    bindUserToTransaction: function() {
      let self = this;
      let qrcodedata = encodeURI(
        "frejaeid://bindUserToTransaction?transactionReference=" + this.authRef
      );
      this.frejaQrCode =
        "https://resources.prod.frejaeid.com/qrcode/generate?qrcodedata=" +
        qrcodedata;
      this.timer = setTimeout(function() {
        self.checkStatus();
      }, 500);
    },
    checkStatus: function() {
      let self = this;
      if (this.authRef != "") {
        this.loading = true;
        this.$http
          .get(this.user.hostname + "/authenticate/status/" + this.auth_uuid)
          .then(function(result) {
            if (result.status == 200) {
              clearTimeout(self.timer);
              self.verifyAuthentication();
            }
            if (result.status == 202) {
              if(result.data.hintCode != undefined)
              {
                if(result.data.hintCode == "EXPIRED"){        
                  self.reset();  
                }else{
                  self.hintMessage = result.data.hintCode;
                  self.statusMessage = self.$t("FREJA.PROCESS." + result.data.hintCode);
                  self.timer = setTimeout(self.checkStatus, 1000);
                }
              }else{
                if(result.data == "EXPIRED"){        
                  self.reset();  
                }else{
                  self.hintMessage = result.data;
                  self.statusMessage = self.$t("FREJA.PROCESS." + result.data);
                  self.timer = setTimeout(self.checkStatus, 1000);
                }
              }       
            }
          })
          .catch(function() {
            self.reset();
          });
      }
    },
    verifyAuthentication: function() {
      let self = this;
      this.$http
        .get(this.user.hostname + "/authenticate/result/" + this.auth_uuid)
        .then(function(result) {
          self.$emit("authenticated", result.data.token);
        })
        .catch(function() {
          self.reset();
        });
    },
  },
  computed: {
    urlDevice() {
      return encodeURI(
        "frejaeid://bindUserToTransaction?transactionReference=" + this.authRef
      );
    },
  },
  mounted: function() {
    this.showOnThisDevice = this.device.isMobile;
    this.loading = false;
  },
};
</script>
<style></style>
