<template>  
  <section>

    <b-overlay :show="loading" rounded="sm">
   
        <button
          v-on:click="loginSubmit"
          class="btn btn-fill btn-block btn-primary"
        >
          {{ $t("START_IDENTIFICATION") }}
        </button>
        
    </b-overlay>

  </section>
</template>
<script>
export default {
  props: ["link_uuid"],
  data() {
    return {
      loading: true,
      auth_uuid: "",
      errorMessage: ""
    };
  },
  methods: {
    loginSubmit: function() {
      this.loading = true;
      let self = this;
      self.$emit("loading",true);
      this.$http
        .post(this.user.hostname + "/message/link/authenticate/", {
          link_uuid: this.link_uuid
        })
        .then(function(result) {
          self.makeRequest(result.data.request_url);
          self.auth_uuid = result.data.auth_uuid;
        })
        .catch(function() {
          self.$emit("loading",false);
          self.loading = false;
        });
    },
    makeRequest: function(url) {
      this.loading = true;
      let self = this;
      this.$http
        .get(url)
        .then(function() {
          self.verifyAuthentication();
        })
        .catch(function() {
          self.$emit("loading",false);
          self.loading = false;
        });
    },
    verifyAuthentication: function() {
      let self = this;
      this.$http
      .get(this.user.hostname + "/authenticate/result/" + this.auth_uuid)
      .then(function(result) {
        self.$emit('authenticated', result.data.token);
      })
      .catch(function() {
        self.$emit("loading",true);
        self.loading = false;
      });
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted: function() {
    this.loading = false;
  }
};
</script>
<style></style>
