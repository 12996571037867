var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.information
    ? _c(
        "section",
        [
          _c("b-overlay", { attrs: { show: _vm.verifying, rounded: "sm" } }, [
            _c("div", { class: { "view-content": _vm.authenticated } }, [
              _c("div", { staticClass: "text-center" }, [
                _vm.information.organisation_image_uuid != null
                  ? _c(
                      "div",
                      { staticClass: "external-logo" },
                      [
                        _c("b-img", {
                          staticClass: "logo",
                          attrs: {
                            fluid: "",
                            src: _vm.organisationImage,
                            alt: "",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _vm.authenticated
                ? _c("div", [
                    _vm.information.message
                      ? _c("div", [
                          _c(
                            "div",
                            { staticClass: "box-content header mb-2 mt-2" },
                            [
                              _vm.responses_after > 0
                                ? _c(
                                    "div",
                                    { staticClass: "text-center" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "no-border",
                                          on: { click: _vm.showConversation },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fal fa-chevron-double-up",
                                          }),
                                          _vm._v(
                                            "  \n                " +
                                              _vm._s(_vm.responses_after) +
                                              " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "CONVERSATION_NEW_MESSAGES"
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.showCloseBtn
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "no-border",
                                      attrs: {
                                        size: "sm",
                                        variant: "secondary",
                                      },
                                      on: { click: _vm.closeBtn },
                                    },
                                    [
                                      _c("i", { staticClass: "fal fa-times" }),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("CLOSE")) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.showReplyBtn
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "btn-fill",
                                      attrs: { size: "sm", variant: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.showReply = true
                                        },
                                      },
                                    },
                                    [
                                      _c("i", { staticClass: "fal fa-reply" }),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("RESPOND")) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "b-button",
                                {
                                  staticClass: "no-border",
                                  attrs: { size: "sm", variant: "secondary" },
                                  on: { click: _vm.printBtn },
                                },
                                [
                                  _c("i", { staticClass: "fal fa-print" }),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("PRINT")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "box-content" }, [
                _c(
                  "div",
                  {
                    class: {
                      "not-authenticated": !_vm.authenticated,
                      authenticated: _vm.authenticated,
                    },
                  },
                  [
                    _vm.error
                      ? _c("div", { staticClass: "text-center mt-2" }, [
                          _c("i", { staticClass: "fal fa-exclamation-circle" }),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("ERROR.COULD_NOT_USE_LINK")) +
                              "\n            "
                          ),
                          1 == 0
                            ? _c(
                                "div",
                                { staticClass: "mt-4" },
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      attrs: {
                                        variant: "primary",
                                        to: { name: "Login" },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("BACK")))]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm.error == false
                      ? _c("div", [
                          !_vm.authenticated
                            ? _c("div", { staticClass: "text-center" }, [
                                _vm.loading == false
                                  ? _c("div", [
                                      _vm.information.authentication_method
                                        ? _c("div", [
                                            _c("p", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(_vm.authMessage1) +
                                                  "\n                    "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(_vm.authMessage2) +
                                                  "\n                    "
                                              ),
                                              _c("b", [
                                                _vm._v(
                                                  " \n                      "
                                                ),
                                                _vm.information
                                                  .authentication_method !=
                                                "siths-card"
                                                  ? _c("img", {
                                                      staticClass: "authIcon",
                                                      attrs: {
                                                        alt: _vm.$t(
                                                          "AUTH_METHODS." +
                                                            _vm.information
                                                              .authentication_method
                                                        ),
                                                        src: _vm.authenticationImage,
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(
                                                  "\n\n                      " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "AUTH_METHODS." +
                                                          _vm.information
                                                            .authentication_method
                                                      )
                                                    )
                                                ),
                                              ]),
                                            ]),
                                          ])
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                                _vm.information.authentication_method
                                  ? _c(
                                      "div",
                                      [
                                        _vm.isEmailOTP
                                          ? _c("AuthEmailOTP", {
                                              attrs: {
                                                link_uuid: _vm.link_uuid,
                                              },
                                              on: {
                                                authenticated:
                                                  _vm.authenticatedDone,
                                                loading: _vm.setLoading,
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.information.authentication_method ==
                                        "sms"
                                          ? _c("AuthSms", {
                                              attrs: {
                                                link_uuid: _vm.link_uuid,
                                              },
                                              on: {
                                                authenticated:
                                                  _vm.authenticatedDone,
                                                loading: _vm.setLoading,
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.information.authentication_method ==
                                        "siths-card"
                                          ? _c("AuthSiths", {
                                              attrs: {
                                                link_uuid: _vm.link_uuid,
                                                siths_info:
                                                  _vm.information.siths,
                                              },
                                              on: {
                                                authenticated:
                                                  _vm.authenticatedDone,
                                                loading: _vm.setLoading,
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.information.authentication_method ==
                                        "sefos-certificate"
                                          ? _c("AuthSefosCertificate", {
                                              attrs: {
                                                link_uuid: _vm.link_uuid,
                                              },
                                              on: {
                                                authenticated:
                                                  _vm.authenticatedDone,
                                                loading: _vm.setLoading,
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.information.authentication_method ==
                                        "se-eid"
                                          ? _c("AuthSeEid", {
                                              attrs: {
                                                link_uuid: _vm.link_uuid,
                                              },
                                              on: {
                                                authenticated:
                                                  _vm.authenticatedDone,
                                                loading: _vm.setLoading,
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.information.authentication_method ==
                                        "sefos-account"
                                          ? _c("AuthSefosAccount", {
                                              attrs: {
                                                link_uuid: _vm.link_uuid,
                                              },
                                              on: {
                                                authenticated:
                                                  _vm.authenticatedDone,
                                                loading: _vm.setLoading,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _c(
                            "div",
                            { attrs: { id: "printMe" } },
                            [
                              _vm.IsMessage(_vm.information.type)
                                ? _c("LinkMessage", {
                                    attrs: {
                                      link_uuid: _vm.link_uuid,
                                      authenticated: _vm.authenticated,
                                      information: _vm.information,
                                      loading: _vm.loading,
                                    },
                                    on: { closeBtn: _vm.closeBtn },
                                  })
                                : _vm._e(),
                              _vm.information.type == "teams-meeting"
                                ? _c("LinkTeamsMeeting", {
                                    attrs: {
                                      link_uuid: _vm.link_uuid,
                                      authenticated: _vm.authenticated,
                                      information: _vm.information,
                                      loading: _vm.loading,
                                      recurrence: _vm.recurrence,
                                    },
                                    on: { closeBtn: _vm.closeBtn },
                                  })
                                : _vm._e(),
                              _vm.information.type == "nuiteq-meeting"
                                ? _c("LinkNuiteqMeeting", {
                                    attrs: {
                                      link_uuid: _vm.link_uuid,
                                      authenticated: _vm.authenticated,
                                      information: _vm.information,
                                      loading: _vm.loading,
                                      recurrence: _vm.recurrence,
                                    },
                                    on: { closeBtn: _vm.closeBtn },
                                  })
                                : _vm._e(),
                              _vm.information.type == "jitsi-meeting"
                                ? _c("LinkJitsiMeeting", {
                                    attrs: {
                                      link_uuid: _vm.link_uuid,
                                      authenticated: _vm.authenticated,
                                      information: _vm.information,
                                      loading: _vm.loading,
                                      recurrence: _vm.recurrence,
                                    },
                                    on: { closeBtn: _vm.closeBtn },
                                  })
                                : _vm._e(),
                              _vm.information.type == "jitsi-secure-meeting"
                                ? _c("LinkJitsiSecureMeeting", {
                                    attrs: {
                                      link_uuid: _vm.link_uuid,
                                      authenticated: _vm.authenticated,
                                      information: _vm.information,
                                      loading: _vm.loading,
                                      recurrence: _vm.recurrence,
                                    },
                                    on: { closeBtn: _vm.closeBtn },
                                  })
                                : _vm._e(),
                              _vm.information.message
                                ? _c("LinkInformation", {
                                    attrs: {
                                      information: _vm.information,
                                      loading: _vm.loading,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]),
              _vm.authenticated
                ? _c("div", [
                    _vm.expire_date
                      ? _c(
                          "div",
                          { staticClass: "helptext mt-2 text-center" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("MESSAGE_IS_RETENTIONED_AT")) +
                                "\n          " +
                                _vm._s(_vm.FormatDateTime(_vm.expire_date)) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.information.message
                      ? _c("div", [
                          _c(
                            "div",
                            { staticClass: "box-content header mb-2 mt-2" },
                            [
                              _vm.responses_before > 0
                                ? _c(
                                    "div",
                                    { staticClass: "text-center mb-2" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "no-border",
                                          on: { click: _vm.showConversation },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fal fa-chevron-double-down",
                                          }),
                                          _vm._v(
                                            "  \n                " +
                                              _vm._s(_vm.responses_before) +
                                              " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "CONVERSATION_OLDER_MESSAGES"
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm.information.message
            ? _c("div", [
                _vm.information.message.settings.require_response == 1
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-sidebar",
                          {
                            key: _vm.showReplyKey,
                            attrs: {
                              "sidebar-class": "big_sidebar",
                              right: "",
                              title: _vm.GetTitle,
                              backdrop: "",
                              "no-slide": "",
                              lazy: "",
                              shadow: "",
                              "no-header-close": "",
                              "no-close-on-backdrop": "",
                              "no-close-on-esc": "",
                            },
                            model: {
                              value: _vm.showReply,
                              callback: function ($$v) {
                                _vm.showReply = $$v
                              },
                              expression: "showReply",
                            },
                          },
                          [
                            _c(
                              "b-overlay",
                              { attrs: { show: _vm.loading, rounded: "sm" } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "view-content create-message px-3 py-2",
                                  },
                                  [
                                    _vm.out_of_office_enabled
                                      ? _c(
                                          "b-alert",
                                          {
                                            attrs: {
                                              show: "",
                                              variant: "warning",
                                            },
                                          },
                                          [
                                            _c(
                                              "h4",
                                              { staticClass: "alert-heading" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "MESSAGE_FROM_SENDER"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.$t("LINK_OUT_OF_OFFICE")
                                                ) +
                                                ", " +
                                                _vm._s(
                                                  _vm.out_of_office_message
                                                ) +
                                                "\n            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("LinkReply", {
                                      staticClass: "mt-2",
                                      attrs: {
                                        message: _vm.information.message,
                                        link_uuid: _vm.link_uuid,
                                      },
                                      on: {
                                        Saving: _vm.Saving,
                                        Sending: _vm.Sending,
                                        closeReply: _vm.closeReply,
                                        SentMessage: _vm.SentMessage,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }