<template>
  <section v-if="information">
    <div>
      <div class="text-center">
        <div class="external-logo" v-if="information.organisation_image_uuid != null"><b-img :alt="information.organisation_name"  class="logo" fluid :src="organisationImage"></b-img></div>
        <div class="external-logo" v-if="information.type == 'jitsi-meeting'" :alt="$t('MESSAGES.TYPE.' + information.type)" ><b-img class="logo" fluid src="/img/jitsi-logo.png"></b-img></div>
        <div class="external-logo" v-if="information.type == 'teams-meeting'" :alt="$t('MESSAGES.TYPE.' + information.type)" ><b-img class="logo" fluid src="/img/microsoft-teams-logo.png"></b-img></div>
      </div>
      <div class="box-content text-center">        
          
        <div class="not-authenticated">

          <div class="">

            <div v-if="error"  class="mb-4 text-center">
              <i class="fal fa-exclamation-circle"></i>
              {{ $t("ERROR.COULD_NOT_USE_LINK") }}
            </div>

            <div class="caption-title">{{ $t("MEETINGS.EMAIL") }}</div>
            <input
              class="mt-2 text-center form-control"
              v-model="email"
              type="text"
              name="email"
              required
              v-on:keyup.enter="getLinkUrl"
              :placeholder="$t('EMAIL')"
            />
            <button
              v-on:click="getLinkUrl" 
                  :disabled="disabled"
              class="mt-2 btn btn-fill btn-block btn-primary"
            >
              {{ $t("NEXT") }}
            </button>

          </div>
        </div>

      </div>
    </div>
  </section>
</template>
<script>
export default {
  components: {
  },
  props: ["message_uuid"],
  data() {
    return {
      loading: false,
      errorMessage: "",
      error: false,
      information: null,
      email: ""
    };
  },
  methods: {
    init(){      
      this.loading = false;
      this.error = false;
      this.getLinkInformation();
    },
    getLinkUrl: function() {
      this.loading = true;
      let self = this;
      this.$http
        .post(this.user.hostname + "/message/link/meeting-link",
        {
          message_uuid: this.message_uuid,
          email: this.email
        })
        .then(function(response) {
          if(response.data.auth_message == "meeting_creator")
          {
            self.$router.push({ name: 'MessageView',  params: { message_uuid: self.message_uuid } }).catch(() => {});        
          }else{
            self.$router.push({ name: 'Link',  params: { link_uuid: response.data.redirect_url } }).catch(() => {});
          }         
        })
        .catch((error) => {
           self.loading = false;
          if (error.response.data != undefined)
          {            
            self.error = true;
            self.errorMessage = error.response.data;
          }
        });
    },
    getLinkInformation: function() {
      this.loading = true;
      let self = this;
      this.$http
        .get(this.user.hostname + "/meeting-info/" + this.message_uuid)
        .then(function(response) {
          self.loading = false;
          if(response.data.auth_message == "meeting_creator")
          {
            self.$router.push({ name: 'MessageView',  params: { message_uuid: self.message_uuid } }).catch(() => {});    
          }else{
            self.information = response.data;
          }
        })
        .catch((error) => {
          self.error = true;
          self.loading = false;
          if (error.response.data != undefined)
          {            
            self.error = true;
            self.errorMessage = error.response.data;
          }
        });
    }
  },
  computed: {
    organisationImage(){
      return this.user.hostname + "/organisation/image/" + this.information.organisation_id + "?uuid=" + this.information.organisation_image_uuid;
    },
    disabled() {
      return this.email == "";
    }
  },
  mounted: function() {
    this.init();
  }
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
 {
  opacity: 0;
}
</style>
