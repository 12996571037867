var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items.length != 0
    ? _c(
        "section",
        [
          _c(
            "div",
            { staticClass: "mt-2" },
            [
              _c("span", { staticClass: "conversation-subject" }, [
                _vm._v(_vm._s(_vm.$t("SUBJECT")) + ": " + _vm._s(_vm.subject)),
              ]),
              _c("br"),
              _c(
                "b-button",
                {
                  staticClass: "no-border",
                  on: { click: _vm.closeConversation },
                },
                [
                  _c("i", { staticClass: "fal fa-times" }),
                  _c("span", { staticClass: "hidden-mobile" }, [
                    _vm._v(" " + _vm._s(_vm.$t("CLOSE"))),
                  ]),
                ]
              ),
              _c(
                "b-button",
                { staticClass: "no-border", on: { click: _vm.refreshList } },
                [_c("i", { staticClass: "fal fa-sync" })]
              ),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.count) +
                    " " +
                    _vm._s(_vm.$t("CONVERSATION_MESSAGES"))
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              key: _vm.conversationKey,
              staticClass: "mt-2",
              staticStyle: { position: "relative" },
            },
            _vm._l(_vm.items, function (item, index) {
              return _c(
                "div",
                { key: index },
                [
                  _c(
                    "b-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      !_vm.isMe(item)
                        ? _c(
                            "b-col",
                            { attrs: { md: "7", cols: "10" } },
                            [
                              _c("LinkConversationItem", {
                                staticClass: "mt-2",
                                attrs: {
                                  link_uuid: _vm.link_uuid,
                                  message_uuid: item.message_uuid,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isMe(item)
                        ? _c(
                            "b-col",
                            {
                              attrs: {
                                md: "7",
                                "offset-md": "5",
                                cols: "10",
                                offset: "2",
                              },
                            },
                            [
                              _c("LinkConversationItem", {
                                staticClass: "mt-2",
                                attrs: {
                                  link_uuid: _vm.link_uuid,
                                  message_uuid: item.message_uuid,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
          _c("b-pagination", {
            staticClass: "mt-4",
            attrs: {
              "total-rows": _vm.count,
              "per-page": _vm.limit,
              align: "center",
              pills: "",
            },
            on: { input: _vm.getResponses },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }