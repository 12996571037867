var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.link_uuid
    ? _c(
        "section",
        [
          _c(
            "b-overlay",
            {
              key: "AddFiles" + _vm.message_uuid,
              attrs: { show: _vm.disableUpload, rounded: "sm" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "overlay",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "text-center" }, [
                          !_vm.totalSize
                            ? _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("ATTACHMENTS_PER_SIZE_LIMIT", {
                                        filesize:
                                          _vm.mbDescription(_vm.maxSize) + "",
                                      })
                                    ) +
                                    "\n          "
                                ),
                              ])
                            : _vm._e(),
                          _vm.totalSize
                            ? _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("ATTACHMENTS_SIZE_LIMIT", {
                                        filesize:
                                          _vm.mbDescription(_vm.maxSize) + "",
                                      })
                                    ) +
                                    "\n          "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1964123371
              ),
            },
            [
              !_vm.message_uuid && _vm.cannotUpload
                ? _c(
                    "b-alert",
                    {
                      staticClass: "mb-2",
                      attrs: { show: "", variant: "info" },
                    },
                    [_vm._v(_vm._s(_vm.cannotUpload))]
                  )
                : _vm._e(),
              _vm.message_uuid
                ? _c(
                    "form",
                    { staticClass: "mt-2" },
                    [
                      _c("FileSelect", {
                        key: "localFiles_" + _vm.localFiles.length,
                        staticClass: "mt-2",
                        attrs: {
                          "show-drop": "",
                          fileType: _vm.fileType,
                          "total-size": _vm.totalSize,
                          "max-size": _vm.maxSize,
                        },
                        on: { added: _vm.UpdatedFiles },
                        model: {
                          value: _vm.localFiles,
                          callback: function ($$v) {
                            _vm.localFiles = $$v
                          },
                          expression: "localFiles",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.files.length != 0
            ? _c(
                "span",
                { key: "localattachments_" + _vm.files.length },
                _vm._l(_vm.files, function (item, index) {
                  return _c(
                    "span",
                    { key: index, staticClass: "ms-fontSize-12 mt-2" },
                    [
                      _c(
                        "span",
                        { class: _vm.filesClass(item) },
                        [
                          _c("Attachment", {
                            attrs: {
                              percentage: _vm.GetPercentage(item),
                              item: item,
                              "is-error": _vm.HasError(item),
                              index: index,
                            },
                            on: { RemoveAttachment: _vm.RemoveFile },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.attachments.length != 0
            ? _c(
                "span",
                { key: "attachments_" + _vm.attachments.length },
                _vm._l(_vm.attachments, function (item, index) {
                  return _c(
                    "span",
                    {
                      key: index,
                      staticClass: "ms-fontSize-12 mt-2 compose-attachment",
                    },
                    [
                      _c("Attachment", {
                        attrs: {
                          "is-uploaded": "",
                          "show-download": _vm.link_uuid != "",
                          item: item,
                          index: index,
                        },
                        on: {
                          RemoveAttachment: _vm.RemoveAttachment,
                          DownloadAttachment: _vm.DownloadAttachment,
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          !_vm.AllValidFilesize
            ? _c("div", { staticClass: "error-text" }, [
                _vm._v(
                  "\n    " +
                    _vm._s(_vm.$t("ERROR.LARGE_FILES")) +
                    " " +
                    _vm._s(_vm.mbDescription(_vm.maxSize) + "") +
                    "\n  "
                ),
              ])
            : _vm._e(),
          !_vm.AllValidNames
            ? _c("div", { staticClass: "error-text" }, [
                _vm._v(
                  "\n    " +
                    _vm._s(_vm.$t("ERROR.TOO_MANY_CHARACTERS")) +
                    " " +
                    _vm._s(256) +
                    "\n  "
                ),
              ])
            : _vm._e(),
          !_vm.allValidText
            ? _c("div", { staticClass: "error-text" }, [
                _vm._v(
                  "\n    " +
                    _vm._s(_vm.$t("ERROR.CHARACTERS_NOT_ALLOWED")) +
                    " " +
                    _vm._s(_vm.notAllowedCharacters) +
                    "\n  "
                ),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }