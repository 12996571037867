<template>
  <section v-if="items.length != 0">

    <div class="mt-2">
          <span class="conversation-subject">{{ $t("SUBJECT") }}: {{ subject }}</span>
          <br />
          <b-button
          @click="closeConversation"
          class="no-border"
        >
          <i class="fal fa-times"></i
          ><span class="hidden-mobile">&nbsp;{{ $t("CLOSE") }}</span>
        </b-button>
  
        <b-button @click="refreshList" class="no-border"
          ><i class="fal fa-sync"></i
        ></b-button>
        <span>{{ count }} {{ $t('CONVERSATION_MESSAGES')}}</span>
     
      </div>

    
    <div class="mt-2" style="position: relative;" :key="conversationKey">
      <div v-for="(item, index) in items" :key="index">
        <b-row no-gutters>
          <b-col md="7" cols="10" v-if="!isMe(item)">
              <LinkConversationItem class="mt-2" :link_uuid="link_uuid" :message_uuid="item.message_uuid"></LinkConversationItem>
          </b-col>
          <b-col md="7" offset-md="5" cols="10" offset="2" v-if="isMe(item)">
            <LinkConversationItem class="mt-2" :link_uuid="link_uuid" :message_uuid="item.message_uuid"></LinkConversationItem>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-pagination
      class="mt-4"
      v-model="currentPage"
      :total-rows="count"
      :per-page="limit"
      align="center"
      @input="getResponses"
      pills
    >
    </b-pagination>
  </section>
</template>
<script>
import LinkConversationItem from "@/components/Link/ConversationItem";  
export default {
  props: {
    link_uuid: {
      default: "",
      type: String,
    }
  },
  components: {
    LinkConversationItem
  },
  data() {
    return {
      firstRun: true,
      subject: "",
      limit: 10,
      conversationIndex: 0,
      showCreate: true,
      response_message_uuid: "",
      items: [],
      count: 0,
      currentPage: 1,
      responded_message_id: "",
      found_page: 0
    };
  },
  methods: {
    closeConversation()
    {
      this.$emit('closeConversation');
    },
    refreshList() {
      this.getResponses();
    },
    isMe(item) {
      return item.user_uuid == "";
    },
    getResponses: function() {
      let self = this;
      this.error = false;
      this.opened = false;
      this.$http
        .post(this.user.hostname + "/message/link/conversation/list", {
          link_uuid: this.link_uuid,
          page: this.currentPage,
          limit: this.limit,
        })
        .then((response) => {
          self.conversationIndex++;
          self.subject = response.data.subject;
          self.response_message_uuid = response.data.response_message_uuid;
          self.items = response.data.items;
          if (self.items.length > 1) {
            self.showCreate = false;
          }
          self.count = response.data.count;
          if(self.firstRun){
            setTimeout(() => {
              self.$scrollTo("#message_" + self.message_uuid.replace(":", ""));
            }, 1000);
          }
          self.firstRun = false;
          self.loading = false;
        })
        .catch(() => {
          self.loading = false;
        });
    },
  },
  computed: {
    conversationKey() {
      return "conversation_" + this.conversationIndex;
    },
  },
  mounted() {
    this.getResponses();
  },
};
</script>
<style lang="css"></style>
