<template>
  <section>
    <div v-if="!loading">
      <b-overlay :show="redirect" rounded="sm">   
        <button
          v-on:click="loginSubmit"
          class="btn btn-fill mt-2 btn-block btn-primary"
        >
          <img class="authIcon" src="/img/auth/net-id-access.png" :alt="$t('AUTH_METHODS.net-id-access')"  />  {{ $t("START") }} {{ $t('AUTH_METHODS.net-id-access') }} 
        </button>
      </b-overlay>
    </div>
    <div class="text-center" v-if="identifier">
      <div v-if="hintMessage == 'OUTSTANDING_TRANSACTION'">
        <div v-if="!showOnThisDevice">
          1. {{ $t("NETID_ACCESS.OUTSTANDING_TRANSACTION") }}.<br />
          2. {{ $t("NETID_ACCESS.USER_SIGN") }}.<br />

          <qrcode-vue
            class="qrcode mt-2 mb-4"
            v-if="identifier != ''"
            :value="startThisDevice"
            :key="identifier"
            size="200" margin="2"
            level="H"
          ></qrcode-vue>

          
          <a variant="primary" :href="startThisDevice">{{ $t("SAME_DEVICE") }}</a>

        </div>
        <div class="mt-2 mb-2" v-if="showOnThisDevice">
           <a variant="primary" class="btn btn-primary btn-block btn-fill text-white" :href="startThisDevice">{{ $t("SAME_DEVICE") }}</a>
          <br />
          <br />
          <a href="#" @click.prevent="otherDevice"> {{ $t("OTHER_DEVICE") }}</a>
        </div>
      </div>

      <div v-if="hintMessage != 'OUTSTANDING_TRANSACTION'">
        <h5>{{ statusMessage }}</h5>
      </div>

      <button v-on:click="cancelSubmit" class="mt-4  btn-block btn btn-secondary">
        {{ $t("CANCEL") }}
      </button>
    </div>
  </section>
</template>
<script>
import mobileDetect from "mobile-device-detect";
import QrcodeVue from "qrcode.vue";
export default {
  props: ["link_uuid"],
  components: {
    QrcodeVue
  },
  data() {
    return {
      redirect: false,
      device: mobileDetect,
      loading: true,
      identifier: "",
      auth_uuid: "",
      statusMessage: "",
      timer: null,
      hintMessage: "",
      errorMessage: "",
      showOnThisDevice: false
    };
  },
  methods: {
    otherDevice: function() {
      this.showOnThisDevice = false;
    },
    reset: function() {
      clearTimeout(this.timer);
      this.loading = false;
      this.identifier = "";
      this.showOnThisDevice = !this.device.isMobile;
      this.$emit('setenterprise', true);
      this.$emit("loading", false);
    },
    cancelSubmit: function() {
      let self = this;
      self.reset();
      /*
      this.$http
        .get(this.user.hostname + "/authenticate/cancel/" + this.auth_uuid);*/
    },
    loginSubmit: async function() {
      this.loading = true;
      let self = this;
      self.$emit("loading", true);
      this.$http
        .post(this.user.hostname + "/message/link/authenticate", {
           link_uuid: this.link_uuid,
           method: "net-id-access"
         })
        .then(function(result) {
          self.$emit('setenterprise', false);
          if(result.data.redirect == 1)
          {
            self.loading = false;
            self.redirect = true;
            window.location.href = result.data.request_url;
          }else{
            self.statusMessage = "";
            self.auth_uuid = result.data.auth_uuid;
            self.identifier = result.data.identifier;
            self.hintMessage = "OUTSTANDING_TRANSACTION";
            self.timer = setTimeout(self.checkStatus, 1000);
          }
        })
        .catch(function() {
          self.reset();
        });
    },
    checkStatus: function() {
      let self = this;
      if (this.identifier != "") {
        this.loading = true;
        this.$http
          .get(this.user.hostname + "/authenticate/status/" + this.auth_uuid)
          .then(function(result) {
            if (result.status == 200) {
              self.identifier = "";
              clearTimeout(self.timer);
              self.verifyAuthentication();
            }
            if (result.status == 202) {
              if(result.data == "TIMEOUT"){     
                self.reset();  
              }else{
                self.hintMessage = result.data;
                self.statusMessage = self.$t("NETID_ACCESS." + result.data);
                self.timer = setTimeout(self.checkStatus, 1000);
              }
            }
          })
          .catch(function() {
            self.reset();
          });
      }
    },
    verifyAuthentication: function() {
      let self = this;
      this.$http
        .get(this.user.hostname + "/authenticate/result/" + this.auth_uuid)
        .then(function(result) {
          self.$emit("authenticated", result.data.token);
        })
        .catch(function() {
          self.reset();
        });
    },
  },
  computed: {
    startThisDevice() {
      return "netid://?autostarttoken=" + this.identifier + "&redirect=NULL"
    }
  },
  mounted: function() {
    this.loading = false;
    this.showOnThisDevice = !this.device.isMobile;
  },
};
</script>
<style></style>