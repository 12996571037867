var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
        !_vm.verifying
          ? _c("div", [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-fill btn-primary",
                  on: { click: _vm.loginSubmit },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("START_IDENTIFICATION")) +
                      "\n      "
                  ),
                ]
              ),
            ])
          : _vm._e(),
        _vm.verifying
          ? _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("div", { staticClass: "caption-title" }, [
                  _vm._v(_vm._s(_vm.$t("EMAIL_OTP"))),
                ]),
                _c("b-form-input", {
                  staticClass: "mt-2 text-center form-control",
                  attrs: {
                    trim: "",
                    type: "tel",
                    name: "code",
                    required: "",
                    placeholder: _vm.$t("CODE"),
                  },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.authenticateIdentifier.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.code,
                    callback: function ($$v) {
                      _vm.code = $$v
                    },
                    expression: "code",
                  },
                }),
                _c("div", { staticClass: "mt-4" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-fill btn-block btn-primary",
                      attrs: { disabled: _vm.disableVerify },
                      on: { click: _vm.authenticateIdentifier },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("VERIFY_BTN")) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "mt-2" }, [
                  _c(
                    "button",
                    {
                      staticClass: "mt-2 btn btn-block btn-secondary",
                      on: { click: _vm.cancelSubmit },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.$t("CANCEL")) + "\n        "
                      ),
                    ]
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }