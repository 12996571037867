var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm.options.length != 0
      ? _c("div", [
          !_vm.loading
            ? _c("div", [
                _vm.siths_info.net_id_enterprise
                  ? _c(
                      "div",
                      [
                        _vm.enterprise
                          ? _c("NetIDEnterprise", {
                              attrs: { link_uuid: _vm.link_uuid },
                              on: {
                                setaccess: _vm.setaccess,
                                authenticated: _vm.authenticated,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.siths_info.net_id_access && _vm.siths_info.net_id_enterprise
                  ? _c("div", [
                      _vm.access
                        ? _c("p", { staticClass: "mt-2 mb-2 strikethrough" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "AUTH_METHODS.DO_YOU_HAVE_NET_ID_ACCESS"
                                  )
                                )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.siths_info.net_id_access
            ? _c(
                "div",
                [
                  _vm.access
                    ? _c("NetIDAccess", {
                        attrs: { link_uuid: _vm.link_uuid },
                        on: {
                          loading: _vm.setLoading,
                          setenterprise: _vm.setenterprise,
                          authenticated: _vm.authenticated,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.options.length == 0
      ? _c(
          "div",
          [
            _c("b-overlay", { attrs: { show: _vm.redirect, rounded: "sm" } }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-fill btn-block btn-primary",
                  on: { click: _vm.loginSubmit },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("START_IDENTIFICATION")) +
                      "\n      "
                  ),
                ]
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }