<template>
  <section class="clearfix" v-if="showThis">
    <hr class="pb-0 mb-0 mt-2 mb-2" />


    <a @click="show = true" class="clickable float-right"><i class="fal fa-info-circle"></i> {{$t("DETAILS")}}</a>
 
    <b-modal
      centered
      v-model="show"
      :dialog-class="teams.theme"
      :title="$t('MESSAGE_DETAILS')"
      ok-only
      :ok-title="$t('CLOSE')"
    >
      <div class="px-3 py-2">
        <h6 class="mb-0 no-uppercase">{{ $t("MESSAGE_UUID") }}</h6>
        {{ information.message.message_uuid }}

        <h6 class="mt-3 mb-0 no-uppercase">{{ $t("CREATOR_UUID") }}</h6>
        {{ information.message.creator.user_uuid }}

        <h6 class="mt-3 mb-0 no-uppercase">{{ $t("MESSAGE_VERSION") }}</h6>
        {{ information.message.version }}

        <h6 class="mt-3 mb-0 no-uppercase">
          {{ $t("LOA.TITLE_PARTICIPANT") }}
        </h6>
        <i
          class="fal fa-info-square"
          v-b-tooltip.hover
          :title="$t('LOA.INFO.' + information.message.settings.loa_level)"
        ></i>
        LOA{{ information.message.settings.loa_level }}

        <h6 class="mt-3 mb-0 no-uppercase">{{ $t("TYPE") }}</h6>
        {{ $t("MESSAGES.TYPE." + information.message.message_type) }}

        <h6
          class="mt-3 mb-0 no-uppercase" v-if="information.message.settings.require_response">
          {{ $t("RESPONSE") }}
        </h6>
        <span  v-if="information.message.settings.require_response">{{
          $t("MESSAGES.REQUESTED_RESPONSE")
        }}</span>
      </div></b-modal
    >
  </section>
</template>
<script>
export default {
  components: {},
  props: ["information", "loading"],
  data() {
    return {
      show: false,
    };
  },
  methods: {},
  computed: {
    showThis()
    {
      return this.information.auth_message != 'open_to_date' && this.information.auth_message != 'open_from_date'
    }
  },
  mounted: function() {},
};
</script>
<style></style>
