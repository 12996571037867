<template>
  <section v-if="link_uuid">
    <b-overlay
      :show="disableUpload"
      rounded="sm"
      :key="'AddFiles' + message_uuid"
    >
      <b-alert show variant="info" class="mb-2" v-if="!message_uuid && cannotUpload">{{ cannotUpload }}</b-alert>

       <form class="mt-2" v-if="message_uuid">
          <FileSelect
            :key="'localFiles_' + localFiles.length"
            show-drop
            v-model="localFiles"
            @added="UpdatedFiles"
            :fileType="fileType"
            class="mt-2"
            :total-size="totalSize"
            :max-size="maxSize"
          ></FileSelect>
        </form>

        <template #overlay>
          <div class="text-center">
            <div v-if="!totalSize">
              {{ $t("ATTACHMENTS_PER_SIZE_LIMIT", { filesize: mbDescription(maxSize) + "" }) }}
            </div>
            <div v-if="totalSize">
              {{ $t("ATTACHMENTS_SIZE_LIMIT", { filesize: mbDescription(maxSize) + "" }) }}
            </div>
          </div>
        </template>
      </b-overlay>

    <span v-if="files.length != 0" :key="'localattachments_' + files.length">
      <span
        v-for="(item, index) in files"
        :key="index"
        class="ms-fontSize-12 mt-2"
      >
        <span :class="filesClass(item)">
          <Attachment
            @RemoveAttachment="RemoveFile"
            :percentage="GetPercentage(item)"
            :item="item"
            :is-error="HasError(item)"
            :index="index"
          ></Attachment>
        </span>
      </span>
    </span>

    <span
      v-if="attachments.length != 0"
      :key="'attachments_' + attachments.length"
    >
      <span
        v-for="(item, index) in attachments"
        :key="index"
        class="ms-fontSize-12 mt-2 compose-attachment"
      >
        <Attachment
          is-uploaded
          :show-download="link_uuid != ''"
          @RemoveAttachment="RemoveAttachment"
          @DownloadAttachment="DownloadAttachment"
          :item="item"
          :index="index"
        ></Attachment>
      </span>
    </span>

    <div class="error-text" v-if="!AllValidFilesize">
      {{ $t("ERROR.LARGE_FILES") }} {{ mbDescription(maxSize) + "" }}
    </div>

    <div class="error-text" v-if="!AllValidNames">
      {{ $t("ERROR.TOO_MANY_CHARACTERS") }} {{ 256 }}
    </div>

    <div class="error-text" v-if="!allValidText">
      {{ $t("ERROR.CHARACTERS_NOT_ALLOWED") }} {{ notAllowedCharacters }}
    </div>
  </section>
</template>
<script>
import FileSelect from "../Compose/FileSelect";
import Attachment from "../Compose/Attachment";
export default {
  components: {
    FileSelect,
    Attachment,
  },
  props: {
    value: Array,
    maxSize: {
      default: 0,
      type: Number,
    },
    totalSize: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    fileType: {
      default: "",
      type: String,
    },
    link_uuid: {
      default: "",
      type: String,
    },
    message_uuid: {
      default: "",
      type: String,
    },
    cannotUpload: {
      default: "",
      type: String,
    }
  },
  data() {
    return {
      localFiles: [],
      files: [],
      attachments: [],
      fileStatus: [],
      loading: false,
      file_bytes: null,
      uploading: false,
      filestorage_enabled: false,
      name: "",
    };
  },
  methods: {
    FindFiles(fileItem) {
      let foundFiles =
        this.files.filter(function (item) {
          return item.name == fileItem.name;
        }).length != 0;
      let foundAttachments =
        this.attachments.filter(function (item) {
          return item.name == fileItem.name;
        }).length != 0;
      return foundFiles || foundAttachments;
    },
    UpdatedFiles() {
      let files = this.localFiles;
      this.localFiles = [];
      for (let ix = 0; ix < files.length; ix++) {
        let found = this.FindFiles(files[ix]);
        if (!found) {
          this.UploadFile(files[ix], "");
        }
      }
    },
    ValidateFileSize(bytes) {
      return bytes < this.maxSize * 1024 * 1024;
    },
    GetPercentage(fileItem) {
      let found = this.fileStatus.filter(function (item) {
        return item.name == fileItem.name;
      });
      if (found.length == 1) {
        return found[0].percentage;
      } else {
        return 0;
      }
    },
    SetPercentage(fileItem, percentage) {
      let found = this.fileStatus.filter(function (item) {
        return item.name == fileItem.name;
      });
      if (found.length == 1) {
        found[0].percentage = percentage;
      }
    },
    SetDone(fileItem) {
      let found = this.fileStatus.filter(function (item) {
        return item.name == fileItem.name;
      });
      if (found.length == 1) {
        found[0].done = true;
      }
    },
    SetError(fileItem, error) {
      let found = this.fileStatus.filter(function (item) {
        return item.name == fileItem.name;
      });
      if (found.length == 1) {
        found[0].errorMessage = error;
        found[0].done = true;
      }
    },
    HasError(fileItem) {
      let found = this.fileStatus.filter(function (item) {
        return item.name == fileItem.name;
      });
      if (found.length == 1) {
        return found[0].errorMessage != "";
      }
      return false;
    },
    RemoveFile(item) {
      this.files = this.files.filter(function (fileItem) {
        return item.name != fileItem.name;
      });
      this.fileStatus = this.fileStatus.filter(function (fileItem) {
        return item.name != fileItem.name;
      });
    },
    async RemoveAttachment(item) {
      if(this.link_uuid)
      {
        item["link_uuid"] = this.link_uuid;
        let self = this;
        self.loading = true;
        this.$http
          .post(
            this.user.hostname + "/message/link/secure/attachment/remove",
            item
          )
          .then(function () {
            self.attachments = self.attachments.filter(function (fileItem) { return fileItem.name != item.name; });
          })
          .catch(function () {});
      }else{
        this.attachments = this.attachments.filter(function (fileItem) { return fileItem.name != item.name; });
      }
    },
    async DownloadAttachment(item) {
      let result = await this.$http.post(
        this.user.hostname + "/message/link/download/generate",
        {
          link_uuid: this.link_uuid,
          attachment_uuid: item.attachment_uuid,
        }
      );
      let downloadUrl =
        this.user.hostname + "/message/link/download/" + result.data.download_uuid;
      this.openUrl(item, downloadUrl);
    },
    openUrl(item, downloadUrl) {
      try {
        let element = document.createElement("a");
        element.setAttribute("target", "_blank");
        element.style = "display: none";
        element.href = downloadUrl;
        element.download = item.name;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      } catch {
        console.log("COULD NOT OPEN ATTACHMENT");
      }
    },
    async UploadFile(item) {
      this.fileStatus.push({
        name: item.name,
        percentage: 0,
        errorMessage: "",
        done: false,
      });
      this.files.push(item);
      if (!this.ValidateFileSize(item.size)) {
        this.SetError(item, "FILESIZE");
        return;
      }
      if (this.totalSize) {
        if (this.TotalFilesize > this.maxSize * 1024 * 1024) {
          this.SetError(item, "FILESIZE");
          return;
        }
      }
      let self = this;
      let formData = new FormData();
      formData.append("attachment", item);
      formData.append("link_uuid", this.link_uuid);
      this.$http
        .post(
          this.user.hostname + "/message/link/secure/attachment",
          formData,
          {
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              const percentage = Math.floor((loaded * 100) / total);
              self.SetPercentage(item, percentage);
            },
          }
        )
        .then((result) => {
          self.SetDone(item);
          self.files = self.files.filter(function (fileItem) {
            return item.name != fileItem.name;
          });
          self.localFiles = self.localFiles.filter(function (fileItem) {
            return item.name != fileItem.name;
          });
          self.fileStatus = self.fileStatus.filter(function (fileItem) {
            return item.name != fileItem.name;
          });
          self.attachments.push(result.data);
        })
        .catch(() => {
          self.SetError(item, "COULD_NOT_UPLOAD");
        });
    },
    filesClass(item) {
      if (this.HasError(item)) {
        return ["is-invalid"];
      }
      if (!this.ValidateFileSize(item.size)) {
        return ["is-invalid"];
      }
      if (item.name.length > 256) {
        return ["is-invalid"];
      }
      if (!this.validateLettersAndNumbers(item.name)) {
        return ["is-invalid"];
      }
      return ["is-valid"];
    },
  },
  computed: {
    ValidTotalSize() {
      return this.TotalFilesize < this.maxSize * 1024 * 1024;
    },
    disableUpload() {
      return !this.AllValidFilesize;
    },
    TotalFilesize() {
      let size = 0;
      this.attachments.forEach((item) => {
        size += item.size;
      });
      this.files.forEach((item) => {
        size += item.size;
      });
      return size;
    },
    AllValidFilesize() {
      if (!this.totalSize) {
        for (let ix = 0; ix < this.files.length; ix++) {
          if (!this.ValidateFileSize(this.files[ix].size)) {
            this.$emit('valid', false);
            return false;
          }
        }
        this.$emit('valid', true);
        return true;
      }else{
        this.$emit('valid', this.ValidTotalSize);
        return this.ValidTotalSize;
      }
    },
    AllValidNames() {
      for (let ix = 0; ix < this.files.length; ix++) {
        if (this.files[ix].name.length > 256) {
          return false;
        }
      }
      return true;
    },
    allValidText() {
      for (let ix = 0; ix < this.files.length; ix++) {
        if (!this.validateLettersAndNumbers(this.files[ix].name)) {
          return false;
        }
      }
      return true;
    },
  },
  watch: {
    attachments(files) {
      this.$emit("input", files);
    },
  },
  mounted() {
    this.attachments = this.value;
  },
};
</script>
<style>
</style>