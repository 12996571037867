<template>
  <section>
    <div v-if="!loading">
      <b-overlay :show="redirect" rounded="sm">
        <button v-on:click="loginSubmit" class="btn  btn-block btn-fill btn-primary">
          {{ $t("NEXT") }}
        </button>
      </b-overlay>
    </div>
     <div class="text-center" v-if="loading && autoStartToken">
      <div v-if="hintMessage == 'outstandingTransaction'">
        <div v-if="!showOnThisDevice">
          1. {{ $t("BANKID_SE.PROCESS.outstandingTransaction.step1") }}.<br />
          2. {{ $t("BANKID_SE.PROCESS.outstandingTransaction.step2") }}.<br />
          3. {{ $t("BANKID_SE.PROCESS.outstandingTransaction.step3") }}.<br />
          4. {{ $t("BANKID_SE.PROCESS.outstandingTransaction.step4") }}.<br />
          
          <qrcode-vue class="qrcode mt-2 mb-2"
            v-if="autoStartToken != ''"
            :value="qrCode"
            size="200" margin="2"
            level="H"
          ></qrcode-vue>

          <a variant="primary" :href="bankidUrlDevice">{{ $t("SAME_DEVICE") }}</a>
        </div>
        <div class="mt-2 mb-2" v-if="showOnThisDevice">
          <a variant="primary" class="btn btn-primary btn-fill btn-block text-white"  :href="bankidUrlDevice">{{ $t("SAME_DEVICE") }}</a>
          <br />
          <br />
          <a href="#" @click.prevent="otherDevice"> {{ $t("OTHER_DEVICE") }}</a>
        </div>
      </div>

      <div v-if="hintMessage != 'outstandingTransaction'">
        <h5>{{ statusMessage }}</h5>
      </div>

      <button v-on:click="cancelSubmit" class="mt-2 btn-block btn btn-secondary">
        {{ $t("CANCEL") }}
      </button>
    </div>
  </section>
</template>
<script>
import mobileDetect from "mobile-device-detect";
import QrcodeVue from "qrcode.vue";
export default {
  components: { QrcodeVue },
  props: ["link_uuid"],
  data() {
    return {
      redirect: false,
      device: mobileDetect,
      loading: true,
      auth_uuid: "",
      statusMessage: "",
      qrCode: "",
      timer: "",
      hintMessage: "",
      errorMessage: "",
      showOnThisDevice: false,
      autoStartToken: ""
    };
  },
  methods: {
    otherDevice: function() {
      this.showOnThisDevice = false;
    },
    reset: function() {
      clearTimeout(this.timer);
      this.loading = false;
      this.showOnThisDevice = this.device.isMobile;
      this.autoStartToken = "";
      this.$emit("loading", false);
    },
    cancelSubmit:async function() {
      clearTimeout(this.timer);
      this.loading = false;
      this.$emit("loading", false);
      this.$http.get(this.user.hostname + "/authenticate/cancel/" + this.auth_uuid);
      this.auth_uuid = "";
    },
    loginSubmit: function() {
      this.loading = true;
      let self = this;
      if (this.email !== "") {
        self.$emit("loading", true);
        this.$http
          .post(this.user.hostname + "/message/link/authenticate", {
            link_uuid: this.link_uuid,
            method: "bankid-se"
          })
          .then(function(result) {
            if(result.data.redirect == 1)
            {
              self.loading = false;
              self.redirect = true;
              window.location.href = result.data.request_url;
            }else{
              self.auth_uuid = result.data.auth_uuid;
              self.qrCode = result.data.qrCode;
              if(result.data.autoStartToken != undefined)
              {   
                self.autoStartToken = result.data.autoStartToken;
              }else{
                self.autoStartToken = result.data.identifier;
              }
              self.checkStatus();
            }
          })
          .catch(function() {
            self.reset();
            self.$emit("loading", false);
            self.loading = false;
          });
      } else {
        self.$emit("loading", false);
      }
    },
    checkStatus: function() {
      if(this.auth_uuid != "")
      {
        this.loading = true;
        let self = this;
        this.$http
          .get(this.user.hostname + "/authenticate/status/" + this.auth_uuid)
          .then(function(result) {
            if (result.status == 200) {
              clearTimeout(self.timer);
              self.verifyAuthentication();
            }
            if (result.status == 202) {
              if(result.data != "")
              {
                if(result.data.hintCode == "startFailed")
                {                
                  self.reset();
                }else{
                  self.hintMessage = result.data.hintCode;    
                  self.qrCode = result.data.qrCode;          
                  self.statusMessage = self.$t("BANKID_SE.PROCESS." + result.data.hintCode);
                  self.timer = setTimeout(function() {
                    self.checkStatus();
                  }, 1500);
                }
              }
            }
          })
          .catch(function(error) {
            self.reset();
            self.$emit("loading", false);
            self.hintMessage = error.response.data;
            self.loading = false;
            clearTimeout(self.timer);
          });
      }
    },
    verifyAuthentication: function() {
      let self = this;
      this.$http
        .get(this.user.hostname + "/authenticate/result/" + this.auth_uuid)
        .then(function(result) {
          self.$emit("authenticated", result.data.token);
        })
        .catch(function() {
          self.reset();
          self.$emit("loading", false);
          self.loading = false;
        });
    },
  },
  computed: {
    bankidUrlDevice() {
      if(this.device.isMobile)
      {
        return "https://app.bankid.com/?autostarttoken=" + this.autoStartToken + "&redirect=null";
      }else{
        return this.bankidUrl;
      }
    },
    bankidUrl() {
      return "bankid:///?autostarttoken=" + this.autoStartToken + "&redirect=null";
    }
  },
  mounted: function() {
    this.showOnThisDevice = this.device.isMobile;
    this.loading = false;
  },
};
</script>
<style></style>
