var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded && _vm.link_uuid
    ? _c(
        "section",
        [
          _c("InputBody", {
            attrs: { limit: 20000, rows: 12 },
            model: {
              value: _vm.CreateLinkAnswerDTO.body,
              callback: function ($$v) {
                _vm.$set(_vm.CreateLinkAnswerDTO, "body", $$v)
              },
              expression: "CreateLinkAnswerDTO.body",
            },
          }),
          _c(
            "div",
            { key: "AddFiles" + _vm.link_uuid },
            [
              _c("InputFiles", {
                attrs: {
                  link_uuid: _vm.link_uuid,
                  message_uuid: _vm.CreateLinkAnswerDTO.message_uuid,
                  "cannot-upload": _vm.$t("DRAFT.SEFOS_LINK"),
                  "max-size": 200,
                },
                on: { valid: (n) => (_vm.validatedFiles = n) },
                model: {
                  value: _vm.CreateLinkAnswerDTO.attachments,
                  callback: function ($$v) {
                    _vm.$set(_vm.CreateLinkAnswerDTO, "attachments", $$v)
                  },
                  expression: "CreateLinkAnswerDTO.attachments",
                },
              }),
            ],
            1
          ),
          _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
          _c("b-button", { on: { click: _vm.closeReply } }, [
            _vm._v(_vm._s(_vm.$t("BACK"))),
          ]),
          _c(
            "b-button",
            {
              staticClass: "btn-fill",
              attrs: { disabled: _vm.saving, variant: "secondary" },
              on: {
                click: function ($event) {
                  return _vm.saveMessage(true)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("SAVE_DRAFT")))]
          ),
          _vm.CreateLinkAnswerDTO.message_uuid != ""
            ? _c(
                "b-button",
                {
                  staticClass: "btn-fill",
                  attrs: {
                    disabled: _vm.disabledSendMessage,
                    variant: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.saveMessage(false)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("SUBMIT")))]
              )
            : _vm._e(),
          _vm.CreateLinkAnswerDTO.message_uuid != ""
            ? _c(
                "b-button",
                {
                  staticClass: "btn-fill float-right",
                  attrs: { disabled: _vm.saving, variant: "danger" },
                  on: { click: _vm.RemoveDraft },
                },
                [_vm._v(_vm._s(_vm.$t("REMOVE")))]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }