var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm.loading && _vm.authenticated
      ? _c(
          "div",
          [
            _c(
              "b-overlay",
              { attrs: { show: _vm.loading, rounded: "sm", opacity: "0.5" } },
              [
                _c(
                  "h4",
                  { staticClass: "m-0 p-0 mb-3" },
                  [
                    _c("b-skeleton-img", {
                      attrs: { "no-aspect": "", height: "30px", width: "20%" },
                    }),
                  ],
                  1
                ),
                _c("b-skeleton", { attrs: { type: "avatar" } }),
                _c("hr", { staticClass: "pb-0 mb-0 mt-2" }),
                _c(
                  "div",
                  { staticClass: "mt-2" },
                  [
                    _c("b-skeleton-img", {
                      attrs: { "no-aspect": "", height: "56px", width: "20%" },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "mt-2" }, [_c("b-skeleton")], 1),
                _c("hr", { staticClass: "pb-0 mb-0 mt-2" }),
                _c("b-skeleton-img", {
                  attrs: { "no-aspect": "", height: "30px", width: "20%" },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    !_vm.loading
      ? _c("div", [
          _vm.information.message
            ? _c(
                "div",
                [
                  _c("UserInfo", {
                    attrs: {
                      image: "/img/default-avatar.png",
                      primary: _vm.information.message.creator.email,
                      secondary: _vm.createdTime,
                    },
                  }),
                  _c("div", { staticClass: "mt-2" }, [
                    _c("b", [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.information.message.subject),
                        },
                      }),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c("hr", { staticClass: "pb-0 mb-0 mt-2" }),
          _vm.information.auth_message == "open_to_date"
            ? _c("div", [
                _c("div", { staticClass: "mt-4 mb-4" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("SECURELINK.EXPIRED")) +
                      "\n      "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.information.auth_message == "open_from_date"
            ? _c("div", { staticClass: "mt-4 mb-4" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("SECURELINK.LOCKED")) +
                    "\n      " +
                    _vm._s(_vm.FormatDateTime(_vm.information.open_from_date)) +
                    "\n    "
                ),
              ])
            : _vm._e(),
          _vm.information.auth_message != "open_to_date" &&
          _vm.information.auth_message != "open_from_date"
            ? _c(
                "div",
                [
                  _c("BodyText", {
                    attrs: { body: _vm.information.message.body },
                  }),
                  _vm.information.message.attachments.length != 0
                    ? _c("div", [
                        _c("hr", { staticClass: "pb-0 mb-0 mt-2" }),
                        _c("div", { staticClass: "mt-2" }, [
                          _vm._v(_vm._s(_vm.$t("ATTACHMENTS"))),
                        ]),
                        _c(
                          "div",
                          { staticClass: "mt-2" },
                          _vm._l(
                            _vm.information.message.attachments,
                            function (item, index) {
                              return _c(
                                "span",
                                { key: index, staticClass: "ms-fontSize-12" },
                                [
                                  _c("Attachment", {
                                    attrs: {
                                      link_uuid: _vm.link_uuid,
                                      item: item,
                                      message_uuid:
                                        _vm.information.message.message_uuid,
                                      index: index,
                                    },
                                  }),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }