<template>
  <section>
    <div v-if="loading && authenticated">
      <b-overlay :show="loading" rounded="sm" opacity="0.5">
        <h4 class="m-0 p-0 mb-3">
          <b-skeleton-img no-aspect height="30px" width="20%"></b-skeleton-img>
        </h4>

        <b-skeleton type="avatar"></b-skeleton>

        <hr class="pb-0 mb-0 mt-2" />

        <div class="mt-2">
          <b-skeleton-img no-aspect height="56px" width="20%"></b-skeleton-img>
        </div>

        <div class="mt-2">
          <b-skeleton></b-skeleton>
        </div>

        <hr class="pb-0 mb-0 mt-2" />
        <b-skeleton-img no-aspect height="30px" width="20%"></b-skeleton-img>
      </b-overlay>
    </div>
    <div v-if="!loading">
      <div v-if="information.message">
        <UserInfo
          image="/img/default-avatar.png"
          :primary="information.message.creator.email"
          :secondary="createdTime"
        ></UserInfo>

        <div class="mt-2">
          <b><span v-html="information.message.subject"></span></b>
        </div>

     
      </div>

      <hr class="pb-0 mb-0 mt-2" />

      <div v-if="information.auth_message == 'open_to_date'">
        <div class="mt-4 mb-4">
          {{ $t("SECURELINK.EXPIRED") }}
        </div>
      </div>
      <div
        v-if="information.auth_message == 'open_from_date'"
        class="mt-4 mb-4"
      >
        {{ $t("SECURELINK.LOCKED") }}
        {{
          FormatDateTime(information.open_from_date)
        }}
      </div>


      <div
        v-if="
          information.auth_message != 'open_to_date' &&
          information.auth_message != 'open_from_date'
        "
      >

        <BodyText :body="information.message.body"></BodyText>

        <div  v-if="information.message.attachments.length != 0">
          
          
          <hr class="pb-0 mb-0 mt-2" />
          <div class="mt-2">{{ $t("ATTACHMENTS") }}</div>


            <div class="mt-2">
              <span
                v-for="(item, index) in information.message.attachments"
                :key="index"
                class="ms-fontSize-12"
              >
                <Attachment 
                  :link_uuid="link_uuid"
                  :item="item"                  
                  :message_uuid="information.message.message_uuid"
                  :index="index"></Attachment>
              </span>
            </div>

        </div>

      </div>
    </div>
  </section>
</template>
<script>
import UserInfo from "@/components/Layout/UserInfo";
import Attachment from "@/components/View/Attachment";
import BodyText from "@/components/View/BodyText";
export default {
  components: {
    UserInfo,
    Attachment,
    BodyText
  },
  props: ["link_uuid", "open_time", "information", "loading", "authenticated"],
  data() {
    return {};
  },
  methods: {
    checkLastRecipient(index) {
      if (this.information.message.recipients == undefined) {
        return true;
      }
      if (this.information.message.recipients.length == 0) {
        return false;
      } else {
        return this.recipientsNoCreator.length - 1 == index;
      }
    },
  },
  computed: {
    recipientsNoCreator() {
      let self = this;
      return this.information.message.recipients.filter(
        (c) => c.email != self.information.message.creator.email
      );
    },
    createdTime() {
      return this.FormatDateTime(this.information.message.created_date);
    },
    createdName() {
      return (
        this.information.message.creator.name +
        ", " +
        this.information.message.creator.organisation_name
      );
    },
  },
  mounted: function () {},
};
</script>
<style></style>
