<template>
  <section v-if="loaded && link_uuid">

      <InputBody
        v-model="CreateLinkAnswerDTO.body"
        :limit="20000"
        :rows="12"
      ></InputBody>

      <div
      :key="'AddFiles' + link_uuid"
    >
      <InputFiles        
        :link_uuid="link_uuid"
        :message_uuid="CreateLinkAnswerDTO.message_uuid"
        v-model="CreateLinkAnswerDTO.attachments"
        :cannot-upload="$t('DRAFT.SEFOS_LINK')"
        :max-size="200"
        @valid="(n) => validatedFiles = n"
      ></InputFiles>
    </div>           

      <hr class="p-0 m-0 mt-2 mb-2" />

      <b-button @click="closeReply">{{ $t("BACK") }}</b-button>
      
      <b-button
      @click="saveMessage(true)"
      :disabled="saving"
      variant="secondary"
      class="btn-fill"
      >{{ $t("SAVE_DRAFT") }}</b-button
    >

      <b-button
        v-if="CreateLinkAnswerDTO.message_uuid != ''"
      :disabled="disabledSendMessage"
        @click="saveMessage(false)"
        variant="primary"
        class="btn-fill"
        >{{ $t("SUBMIT") }}</b-button
      >

      <b-button
        v-if="CreateLinkAnswerDTO.message_uuid != ''"
        @click="RemoveDraft"
        :disabled="saving"
        variant="danger"
        class="btn-fill float-right"
        >{{ $t("REMOVE") }}</b-button
      >


  </section>
</template>
<script>
import InputBody from "@/components/Input/Body";
import InputFiles from "@/components/Input/LinkFiles";
export default {
  props: ["message", "link_uuid", "link_token"],
  components: {
    InputBody,
    InputFiles,
  },
  data() {
    return {
      timeout: 2000,
      canSave: false,
      timer: null,
      loaded: false,
      loading: false,
      saving: false,
      validatedFiles: true,
      CreateLinkAnswerDTO: {
        message_uuid: '',
        link_uuid: this.link_uuid,
        body: '',
        attachments: [],
        isDraft: true
      },
      responded: false,
      attachmentIndex: 0
    };
  },
  methods: {
    buildReplyContent() {
      let sent = this.FormatDateTime(this.message.created_date);
      let content =
        "From: " +
        this.message.creator.name +
        " - " +
        this.message.creator.email;
      content = content + "\nSent: " + sent;
      content = content + "\nSubject: " + this.message.subject;
      content = content + "\n\n" + this.message.body;
      return content;
    },
    closeReply() {
      this.CreateLinkAnswerDTO = {
        message_uuid: '',
        validatedFiles: true,
        link_uuid: this.link_uuid,
        body: '',
        attachments: [],
        isDraft: true
      };
      this.$emit("closeReply");
    },
    async saveMessage(isDraft) {
      let self = this;            
      this.saving = true;      
      self.loading = true;
      if(!isDraft)
      {
        this.$emit('Sending',true);
      }else{
        this.$emit('Saving', true);
      }
      this.CreateLinkAnswerDTO.isDraft = isDraft;
      this.$http
        .post(this.user.hostname + "/message/link/secure", this.CreateLinkAnswerDTO)
        .then(function (response) {
          self.CreateLinkAnswerDTO.message_uuid = response.data.message_uuid;
          if(!self.CreateLinkAnswerDTO.isDraft)
          {
            self.closeReply();
            self.$emit('SentMessage');
            self.$noty.info(self.$t("SENT_MESSAGE"));
          }
          self.canSave = true;
          self.loading = false; 
          self.saving = false;
          self.$emit('Saving',false);
          self.$emit('Sending',false);
        })
        .catch(function () { 
          self.saving = false;
          self.loading = false;
          self.$emit('Saving',false);
          self.$emit('Sending',false);
        });
    },
    async RemoveDraft() {
      this.loading = true;
      this.saving = true;
      let self = this;
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function (value) {
          if (value) {
            self.$http
              .post(self.user.hostname + "/message/link/secure/remove", {
                link_uuid: self.link_uuid
              })
              .then(() => {
                self.loading = false;
                self.$noty.info(self.$t("REMOVED"));
                self.closeReply();
              })
              .catch(() => {
                self.loading = false;
              });
          }
        })
        .catch(function () {});
    },
    MoveCursorTop()
    {
      setTimeout(() => {
        let input = document.getElementById("messageContent");
        if(input != null)
        {
          input.setSelectionRange(0, 0);
        }
        input.focus();
      }, 200); 
    },
    debounceSave() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.saveMessage(true);
      }, this.timeout);
    },
    async GetInformation() {
      try {
        this.loading = false;
        let result = await this.$http.get(this.user.hostname + "/message/link/draft/" + this.link_uuid);
        if(result.data != null)
        {
          this.CreateLinkAnswerDTO = result.data;          
          setTimeout(() => {
            this.canSave = true;
          }, this.timeout);
        }
        if(!this.CreateLinkAnswerDTO.message_uuid)
        {
          this.CreateLinkAnswerDTO.body = "\n\n---------------------------------------------------------\n" + this.buildReplyContent();                
          this.MoveCursorTop();
        }        
        this.loaded = true;
        this.saving = false;
      // eslint-disable-next-line no-empty
      } catch (e) {
        this.saving = false;
        this.loaded = true;
      }
    },
  },
  computed: {
    disabledSendMessage() {
      if (this.CreateLinkAnswerDTO.body.length > 20000) {
        return true;
      }
      if (this.CreateLinkAnswerDTO.message_uuid == '') {
        return true;
      }
      if (this.saving) {
        return true;
      }
      if (!this.validatedFiles) {
        return true;
      }
      return false;
    }
  },
  watch: {
    "CreateLinkAnswerDTO.body": function () {
      if (this.canSave) {
        this.debounceSave();
      }
    }
  },
  mounted() {
    this.CreateLinkAnswerDTO.link_uuid = this.link_uuid;
    this.GetInformation();
  },
};
</script>
<style lang="css"></style>
