var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      !_vm.conversation
        ? _c("LinkView", {
            attrs: {
              verify: _vm.verify,
              link_uuid: _vm.link_uuid,
              auth_uuid: _vm.auth_uuid,
            },
            on: { showConversation: _vm.showConversation },
          })
        : _vm._e(),
      _vm.conversation
        ? _c("LinkConversation", {
            attrs: { link_uuid: _vm.link_uuid },
            on: { closeConversation: _vm.closeConversation },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }