var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.information
    ? _c("section", [
        _c("div", [
          _c("div", { staticClass: "text-center" }, [
            _vm.information.organisation_image_uuid != null
              ? _c(
                  "div",
                  { staticClass: "external-logo" },
                  [
                    _c("b-img", {
                      staticClass: "logo",
                      attrs: {
                        alt: _vm.information.organisation_name,
                        fluid: "",
                        src: _vm.organisationImage,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.information.type == "jitsi-meeting"
              ? _c(
                  "div",
                  {
                    staticClass: "external-logo",
                    attrs: {
                      alt: _vm.$t("MESSAGES.TYPE." + _vm.information.type),
                    },
                  },
                  [
                    _c("b-img", {
                      staticClass: "logo",
                      attrs: { fluid: "", src: "/img/jitsi-logo.png" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.information.type == "teams-meeting"
              ? _c(
                  "div",
                  {
                    staticClass: "external-logo",
                    attrs: {
                      alt: _vm.$t("MESSAGES.TYPE." + _vm.information.type),
                    },
                  },
                  [
                    _c("b-img", {
                      staticClass: "logo",
                      attrs: {
                        fluid: "",
                        src: "/img/microsoft-teams-logo.png",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "box-content text-center" }, [
            _c("div", { staticClass: "not-authenticated" }, [
              _c("div", {}, [
                _vm.error
                  ? _c("div", { staticClass: "mb-4 text-center" }, [
                      _c("i", { staticClass: "fal fa-exclamation-circle" }),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("ERROR.COULD_NOT_USE_LINK")) +
                          "\n          "
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "caption-title" }, [
                  _vm._v(_vm._s(_vm.$t("MEETINGS.EMAIL"))),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.email,
                      expression: "email",
                    },
                  ],
                  staticClass: "mt-2 text-center form-control",
                  attrs: {
                    type: "text",
                    name: "email",
                    required: "",
                    placeholder: _vm.$t("EMAIL"),
                  },
                  domProps: { value: _vm.email },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.getLinkUrl.apply(null, arguments)
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.email = $event.target.value
                    },
                  },
                }),
                _c(
                  "button",
                  {
                    staticClass: "mt-2 btn btn-fill btn-block btn-primary",
                    attrs: { disabled: _vm.disabled },
                    on: { click: _vm.getLinkUrl },
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(_vm.$t("NEXT")) + "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }