<template>
  <section>
    <b-overlay :show="verifying" rounded="sm">
      <div v-if="!loading">
        <button
          v-on:click="loginSubmit"
          class="btn btn-block btn-fill btn-primary"
        >
          {{ $t("START_IDENTIFICATION") }}
        </button>
      </div>
      <div class="text-center" v-if="loading && !verifying">
        <div class="caption-title">{{ $t("SMS.SPECIFY_CODE") }}</div>
        <b-form-input trim
          class="mt-2 text-center form-control"
          v-model="code"
          type="tel"
          name="code"
          required
          v-on:keyup.enter="authenticateIdentifier"
          :placeholder="$t('SMS.SPECIFY_CODE_PLACEHOLDER')"
        ></b-form-input>
        <div class="mt-4">
          <button
            v-on:click="authenticateIdentifier"
            :disabled="disableVerify"
            class="btn btn-fill btn-block btn-primary"
          >
            {{ $t("VERIFY_BTN") }}
          </button>
        </div>
        <div class="mt-2">
          <button
            v-on:click="cancelSubmit"
            class="mt-2 btn btn-block  btn-secondary"
          >
            {{ $t("CANCEL") }}
          </button>
        </div>
      </div>
    </b-overlay>
  </section>
</template>
<script>
export default {
  props: ["link_uuid"],
  data() {
    return {
      verifying: false,
      loading: true,
      statusMessage: "",
      code: "",
      auth_uuid: "",
      hintMessage: "",
      errorMessage: "",
    };
  },
  methods: {
    cancelSubmit: function() {
      let self = this;
      clearTimeout(this.timer);
      self.loading = false;
      self.$emit("loading", false);
      this.$http
        .get(this.user.hostname + "/authenticate/cancel/" + this.auth_uuid)
        .then(function() {});
    },
    loginSubmit: function() {
      let self = this;
      self.$emit("loading", true);
      this.$http
        .post(this.user.hostname + "/message/link/authenticate/", {
          link_uuid: this.link_uuid,
        })
        .then(function(result) {
          self.loading = true;
          self.auth_uuid = result.data.auth_uuid;
          self.$emit("loading", true);
        })
        .catch(function() {
          self.$emit("loading", false);
          self.loading = false;
        });
    },
    authenticateIdentifier: function() {
      let self = this;
      this.verifying = true;
      this.$http
        .post(this.user.hostname + "/authenticate/verify", {
          auth_uuid: this.auth_uuid,
          code: this.code.trim(),
        })
        .then(function(result) {
          self.$emit("authenticated", result.data.token);
        })
        .catch(function() {
          self.verifying = false;
          self.$emit("loading", false);
          self.loading = false;
        });
    },
  },
  computed: {
    disableVerify: function() {
      return this.code.length != 6;
    },
  },
  mounted: function() {
    this.loading = false;
  },
};
</script>
<style></style>
