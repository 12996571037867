var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
        !_vm.verifying
          ? _c("div", [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-fill btn-primary",
                  on: { click: _vm.loginSubmit },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("START_IDENTIFICATION")) +
                      "\n      "
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }