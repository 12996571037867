var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm.options.length != 0
      ? _c("div", [
          _c(
            "div",
            [
              !_vm.loading
                ? _c("b-form-select", {
                    staticClass: "mb-2",
                    attrs: {
                      name: "se_eid_authentication_method",
                      options: _vm.options,
                    },
                    model: {
                      value: _vm.authentication_method,
                      callback: function ($$v) {
                        _vm.authentication_method = $$v
                      },
                      expression: "authentication_method",
                    },
                  })
                : _vm._e(),
              _vm.authentication_method == "bankid-se"
                ? _c("Bankid", {
                    attrs: { link_uuid: _vm.link_uuid },
                    on: {
                      authenticated: _vm.authenticatedDone,
                      loading: _vm.setLoading,
                    },
                  })
                : _vm._e(),
              _vm.authentication_method == "freja"
                ? _c("Freja", {
                    attrs: { link_uuid: _vm.link_uuid },
                    on: {
                      authenticated: _vm.authenticatedDone,
                      loading: _vm.setLoading,
                    },
                  })
                : _vm._e(),
              _vm.authentication_method == null
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-block btn-fill btn-primary",
                      attrs: { disabled: true },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("START_IDENTIFICATION")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.options.length == 0
      ? _c(
          "div",
          [
            _c("b-overlay", { attrs: { show: _vm.redirect, rounded: "sm" } }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-fill btn-primary btn-block",
                  on: { click: _vm.loginSubmit },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("START_IDENTIFICATION")) +
                      "\n      "
                  ),
                ]
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }