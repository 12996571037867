<template>
  <section>
    <div v-if="options.length != 0"> 
      <div>
        <b-form-select name="se_eid_authentication_method" v-if="!loading"
          v-model="authentication_method"
          :options="options"
          class="mb-2"
        ></b-form-select>

        <Bankid
          @authenticated="authenticatedDone"
          @loading="setLoading"
          :link_uuid="link_uuid"
          v-if="authentication_method == 'bankid-se'"
        ></Bankid>
        <Freja
          @authenticated="authenticatedDone"
          @loading="setLoading"
          :link_uuid="link_uuid"
          v-if="authentication_method == 'freja'"
        ></Freja>
        <button :disabled="true" v-if="authentication_method == null" class="btn  btn-block btn-fill btn-primary">
          {{ $t("START_IDENTIFICATION") }}
        </button>
      </div>      
    </div>
    <div v-if="options.length == 0"> 
      <b-overlay :show="redirect" rounded="sm">
        <button class="btn btn-fill btn-primary  btn-block"  @click="loginSubmit">
          {{ $t("START_IDENTIFICATION") }}
        </button>
      </b-overlay>
    </div>
  </section>
</template>
<script>
import Freja from "@/components/Link/Auth/SE/Freja.vue";
import Bankid from "@/components/Link/Auth/SE/Bankid.vue";
export default {
  components: { Freja, Bankid },
  props: ["link_uuid"],
  data() {
    return {
      methods: [],
      authentication_method: null,
      loading: false,
      options: [],
      redirect: false
    };
  },
  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("loading", value);
    },
    authenticatedDone(link_token) {
      this.$emit("authenticated", link_token);
    },
    popuplateMethods() {
      for (let ix = 0; ix < this.methods.length; ix++) {
        let method = this.methods[ix];
        if(method.auth_type == "se-eid")
        {
          if(method.childs.length > 1)
          {
            this.options.push({
                value: null,
                text: this.$t("AUTH_METHODS.SELECT_VERIFICATION_METHOD"),
              });
          }
          for (let ix = 0; ix < method.childs.length; ix++) {
            let childMethod = method.childs[ix];
            if(method.childs.length == 1)
            {
              this.authentication_method = childMethod.auth_type;
            }
            if (childMethod.auth_method != "") {
              this.options.push({
                value: childMethod.auth_method,
                text: this.$t("AUTH_METHODS." + childMethod.auth_method),
              });
            }
          }
        }
      }
    },
    loginSubmit: function() {
      this.loading = true;
      let self = this;
      if (this.email !== "") {
        self.$emit("loading", true);
        this.$http
          .post(this.user.hostname + "/message/link/authenticate", {
            link_uuid: this.link_uuid,
            method: ""
          })
          .then(function(result) {
            self.loading = false;
            self.redirect = true;
            window.location.href = result.data.request_url;
          })
          .catch(function() {
            self.$emit("loading", false);
            self.loading = false;
          });
      } else {
        self.$emit("loading", false);
      }
    },
    async getMethods() {
      let self = this;
      await this.$http
        .get(this.user.hostname + "/authenticate/external/methods")
        .then(function(result) {
          self.methods = result.data;
          self.popuplateMethods();
        })
        .catch(function() {
          self.loading = false;
        });
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted: function() {
    this.getMethods();
  },
};
</script>
<style></style>
