<template>
  <section v-if="information">
    <b-overlay :show="verifying" rounded="sm">
      <div v-bind:class="{ 'view-content': authenticated }">
        <div class="text-center">
          <div
            class="external-logo"
            v-if="information.organisation_image_uuid != null"
          >
            <b-img class="logo" fluid :src="organisationImage" alt></b-img>
          </div>
        </div>
        <div v-if="authenticated">
          <div v-if="information.message">
            <div class="box-content header mb-2 mt-2">
              <div class="text-center" v-if="responses_after > 0">
                <b-button class="no-border" @click="showConversation">
                  <i class="fal fa-chevron-double-up"></i> &nbsp;
                  {{ responses_after }} {{ $t("CONVERSATION_NEW_MESSAGES") }}
                </b-button>
              </div>

              <b-button
                size="sm"
                variant="secondary"
                class="no-border"
                v-if="showCloseBtn"
                @click="closeBtn"
              >
                <i class="fal fa-times"></i>
                {{ $t("CLOSE") }}
              </b-button>

              <b-button
                size="sm"
                variant="primary"
                v-if="showReplyBtn"
                @click="showReply = true"
                class="btn-fill"
              >
                <i class="fal fa-reply"></i>
                {{ $t("RESPOND") }}
              </b-button>

              <b-button
                size="sm"
                @click="printBtn"
                variant="secondary"
                class="no-border"
              >
                <i class="fal fa-print"></i>
                {{ $t("PRINT") }}
              </b-button>
            </div>
          </div>
        </div>

        <div class="box-content">
          <div
            v-bind:class="{
              'not-authenticated': !authenticated,
              authenticated: authenticated,
            }"
          >
            <div v-if="error" class="text-center mt-2">
              <i class="fal fa-exclamation-circle"></i>
              {{ $t("ERROR.COULD_NOT_USE_LINK") }}
              <div class="mt-4" v-if="1 == 0">
                <b-btn variant="primary" :to="{ name: 'Login' }">{{
                  $t("BACK")
                }}</b-btn>
              </div>
            </div>

            <div v-if="error == false">
              <div class="text-center" v-if="!authenticated">
                <div v-if="loading == false">
                  <div v-if="information.authentication_method">
                    <p>
                      {{ authMessage1 }}
                      <br />
                      {{ authMessage2 }}
                      <b
                        >&nbsp;
                        <img
                          class="authIcon"
                          :alt="$t('AUTH_METHODS.' + information.authentication_method)"
                          :src="authenticationImage"
                          v-if="
                            information.authentication_method != 'siths-card'
                          "
                        />

                        {{
                          $t(
                            "AUTH_METHODS." + information.authentication_method
                          )
                        }}</b
                      >
                    </p>
                  </div>
                </div>
                <div v-if="information.authentication_method">
                  <AuthEmailOTP
                    @authenticated="authenticatedDone"
                    @loading="setLoading"
                    :link_uuid="link_uuid"
                    v-if="isEmailOTP"
                  ></AuthEmailOTP>
                  <AuthSms
                    @authenticated="authenticatedDone"
                    @loading="setLoading"
                    :link_uuid="link_uuid"
                    v-if="information.authentication_method == 'sms'"
                  ></AuthSms>
                  <AuthSiths
                    @authenticated="authenticatedDone"
                    @loading="setLoading"
                    :link_uuid="link_uuid"
                    :siths_info="information.siths"
                    v-if="information.authentication_method == 'siths-card'"
                  ></AuthSiths>
                  <AuthSefosCertificate
                    @authenticated="authenticatedDone"
                    @loading="setLoading"
                    :link_uuid="link_uuid"
                    v-if="
                      information.authentication_method == 'sefos-certificate'
                    "
                  ></AuthSefosCertificate>
                  <AuthSeEid
                    @authenticated="authenticatedDone"
                    @loading="setLoading"
                    :link_uuid="link_uuid"
                    v-if="information.authentication_method == 'se-eid'"
                  ></AuthSeEid>
                  <AuthSefosAccount
                    @authenticated="authenticatedDone"
                    @loading="setLoading"
                    :link_uuid="link_uuid"
                    v-if="information.authentication_method == 'sefos-account'"
                  ></AuthSefosAccount>
                </div>
              </div>
              <div id="printMe">
                <LinkMessage
                  v-if="IsMessage(information.type)"
                  :link_uuid="link_uuid"
                  :authenticated="authenticated"
                  :information="information"
                  :loading="loading"
                  @closeBtn="closeBtn"
                ></LinkMessage>
                <LinkTeamsMeeting
                  v-if="information.type == 'teams-meeting'"
                  :link_uuid="link_uuid"
                  :authenticated="authenticated"
                  :information="information"
                  :loading="loading"
                  :recurrence="recurrence"
                  @closeBtn="closeBtn"
                ></LinkTeamsMeeting>
                <LinkNuiteqMeeting
                  v-if="information.type == 'nuiteq-meeting'"
                  :link_uuid="link_uuid"
                  :authenticated="authenticated"
                  :information="information"
                  :loading="loading"
                  :recurrence="recurrence"
                  @closeBtn="closeBtn"
                ></LinkNuiteqMeeting>
                <LinkJitsiMeeting
                  v-if="information.type == 'jitsi-meeting'"
                  :link_uuid="link_uuid"
                  :authenticated="authenticated"
                  :information="information"
                  :loading="loading"
                  :recurrence="recurrence"
                  @closeBtn="closeBtn"
                ></LinkJitsiMeeting>
                <LinkJitsiSecureMeeting
                  v-if="information.type == 'jitsi-secure-meeting'"
                  :link_uuid="link_uuid"
                  :authenticated="authenticated"
                  :information="information"
                  :loading="loading"
                  :recurrence="recurrence"
                  @closeBtn="closeBtn"
                ></LinkJitsiSecureMeeting>
                <LinkInformation
                  v-if="information.message"
                  :information="information"
                  :loading="loading"
                ></LinkInformation>
              </div>
            </div>
          </div>
        </div>
        <div v-if="authenticated">
          <div class="helptext mt-2 text-center" v-if="expire_date">
            {{ $t("MESSAGE_IS_RETENTIONED_AT") }}
            {{ FormatDateTime(expire_date) }}
          </div>

          <div v-if="information.message">
            <div class="box-content header mb-2 mt-2">
              <div class="text-center mb-2" v-if="responses_before > 0">
                <b-button class="no-border" @click="showConversation">
                  <i class="fal fa-chevron-double-down"></i> &nbsp;
                  {{ responses_before }} {{ $t("CONVERSATION_OLDER_MESSAGES") }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
    <div v-if="information.message">
      <div v-if="information.message.settings.require_response == 1">
        <b-sidebar
          sidebar-class="big_sidebar"
          v-model="showReply"
          right
          :title="GetTitle"
          backdrop
          no-slide
          lazy
          shadow
          no-header-close
          no-close-on-backdrop
          no-close-on-esc
          :key="showReplyKey"
        >
          <b-overlay :show="loading" rounded="sm">
            <div class="view-content create-message px-3 py-2">
              
              <b-alert show v-if="out_of_office_enabled" variant="warning">
                <h4 class="alert-heading">{{ $t("MESSAGE_FROM_SENDER") }}</h4>
                {{ $t("LINK_OUT_OF_OFFICE") }}, {{ out_of_office_message }}
              </b-alert>

              <LinkReply
                class="mt-2"
                @Saving="Saving"
                @Sending="Sending"
                @closeReply="closeReply"
                @SentMessage="SentMessage"
                :message="information.message"
                :link_uuid="link_uuid"
              ></LinkReply>
            </div>
          </b-overlay>
        </b-sidebar>
      </div>
    </div>
  </section>
</template>
<script>
import LinkMessage from "@/components/Link/Information/RegularMessage";
import LinkNuiteqMeeting from "@/components/Link/Information/NuiteqMeeting";
import LinkTeamsMeeting from "@/components/Link/Information/TeamsMeeting";
import LinkJitsiMeeting from "@/components/Link/Information/JitsiMeeting";
import LinkJitsiSecureMeeting from "@/components/Link/Information/JitsiSecureMeeting";
import LinkInformation from "@/components/Link/Information";
import LinkReply from "@/components/Link/LinkReply";
import AuthSms from "@/components/Link/Auth/Sms.vue";
import AuthEmailOTP from "@/components/Link/Auth/EmailOTP.vue";
import AuthSefosCertificate from "@/components/Link/Auth/SefosCertificate.vue";
import AuthSiths from "@/components/Link/Auth/Siths/Siths.vue";
import AuthSeEid from "@/components/Link/Auth/SE/eID.vue";
import AuthSefosAccount from "@/components/Link/Auth/SefosAccount.vue";
export default {
  components: {
    LinkNuiteqMeeting,
    LinkInformation,
    LinkJitsiMeeting,
    LinkMessage,
    LinkTeamsMeeting,
    AuthSeEid,
    AuthSms,
    AuthSefosCertificate,
    AuthSiths,
    LinkReply,
    AuthEmailOTP,
    LinkJitsiSecureMeeting,
    AuthSefosAccount,
  },
  props: ["verify", "link_uuid", "auth_uuid"],
  data() {
    return {
      saving: false,
      sending: false,
      responses_after: 0,
      responses_before: 0,
      showReplyIndex: 0,
      expire_date: null,
      showReply: false,
      open_time: false,
      modalShow: false,
      verifying: false,
      loading: false,
      identifier: "",
      authenticated: false,
      errorMessage: "",
      link_token: "",
      error: false,
      responded_message_id: "",
      recurrence: null,
      formInput: "",
      image: "img/login-image.png",
      information: {},
      out_of_office_enabled: false,
      out_of_office_message: "",
    };
  },
  methods: {
    SentMessage()
    {
      this.getMessagesBeforeAfter();
    },
    Saving(val)
    {
      this.saving = val;
    },
    Sending(val)
    {
      this.sending = val;
    },
    showConversation() {
      this.$emit("showConversation");
    },
    closeReply() {
      this.showReplyIndex++;
      return (this.showReply = false);
    },
    printBtn() {
      window.print();
    },
    init() {
      this.loading = false;
      this.error = false;
      let cookieToken = this.$cookies.get("link_token");
      if (cookieToken !== null) {
        this.link_token = cookieToken;
      }
      this.getLinkInformation();
    },
    setResponse() {
      this.getLinkInformation();
      this.closeReply();
    },
    setLoading(value) {
      this.loading = value;
    },
    async closeBtn() {
      if (this.information.authentication_method == "sefos-account") {
        await this.$store.dispatch("user/NotAuthenticated");
        this.$cookies.set("token", "", "1s", "", "", true, "None");
        this.$cookies.remove("token");
      }
      await this.$store.dispatch("user/setLinkToken", "");
      this.$cookies.set("link_token", "", "1s", "", "", true, "None");
      this.$cookies.remove("link_token");
      this.getLinkInformation();
    },
    async authenticatedDone(link_token) {
      await this.$store.dispatch("user/setLinkToken", link_token);
      this.link_token = link_token;
      this.loading = false;
      //this.$cookies.set("link_token", link_token, "5min", "", "", true, "None");
      this.$cookies.set("link_token", link_token, 0);
      this.$cookies.set("redirect_url", "");
      await this.$store.dispatch("user/setRedirectUrl", "");
      this.getLinkInformation();
    },
    async setLinkUrl() {
      let redirect_url = this.$router.currentRoute.path;
      //this.$cookies.set("redirect_url", redirect_url, "3m", "", "", true, "None");
      this.$cookies.set("redirect_url", redirect_url);
      await this.$store.dispatch("user/setRedirectUrl", redirect_url);
    },
    async getLinkInformation () {
      this.loading = true;
      try {
        let result = await this.$http.get(this.user.hostname + "/message/link/" + this.link_uuid);
        this.loading = false;
        this.authenticated = false;
        if (result.data.auth_message == "authenticated") {
          this.authenticated = true;
        }
        if (result.data.auth_message == "open_to_date") {
          this.authenticated = true;
        }
        if (result.data.auth_message == "open_from_date") {
          this.authenticated = true;
        }        
        this.recurrence = result.data.recurrence;
        this.information = result.data;
        if (this.authenticated) {
          this.getMessagesBeforeAfter();
          this.GetOutOfOffice();
          this.GetExpireDate();
        } else {
          this.setLinkUrl();
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.loading = false;
        if (error.response.data != undefined) {
          this.error = true;
          this.errorMessage = error.response.data;
        }
        console.log("Could not getLinkInformation");
      }
    },
    async GetOutOfOffice() {
      try {
        let result = await this.$http.get(this.user.hostname + "/message/link/out-of-office/" + this.link_uuid);
        this.out_of_office_enabled = result.data.out_of_office_enabled;
        this.out_of_office_message = result.data.out_of_office_message;
      } catch {
        console.log("Error GetOutOfOffice");
      }        
    },
    async GetExpireDate() {
      if(this.IsMessage(this.information.message.message_type))
      {
        try {
          let result = await this.$http
            .get(this.user.hostname + "/message/link/expire/" + this.link_uuid);
          this.expire_date = result.data;
        } catch {
          console.log("Error GetExpireDate");
        }
      }
    },
    async getMessagesBeforeAfter() {
      if(this.IsMessage(this.information.message.message_type))
      {
        try {
          let result = await this.$http
          .post(this.user.hostname + "/message/link/conversation/before-after", {
            link_uuid: this.link_uuid,
          });
          this.responses_after = result.data.after;
          this.responses_before = result.data.before;
        } catch {
          console.log("Error getMessagesBeforeAfter");
        }
      }
    },
    async sendAuthentication() {
      try {
        let result = await this.$http.get(this.user.hostname + "/message/link/authenticate/" + this.link_uuid);        
        if (result.data.method == "redirect") {
          location.href = result.data.redirect_url;
        }
      } catch {
          console.log("Error sendAuthentication");
      }        
    },
    async verifyAuthentication() {
      try {
        this.verifying = true;
        let result = await this.$http
          .get(this.user.hostname + "/authenticate/result/" + this.auth_uuid);
        this.verifying = false;
        this.authenticatedDone(result.data.token);
      } catch {
        this.verifying = false;
        this.init();
        console.log("Error verifyAuthentication");
      }            
    },
  },
  computed: {
    GetTitle() {
      if (this.saving) {
        if (!this.sending) {
          return (
            this.$t("MESSAGES.TYPE.message") +
            " (" +
            this.$t("SAVING") +
            " .. )"
          );
        } else {
          return (
            this.$t("MESSAGES.TYPE.message") +
            " (" +
            this.$t("SENDING") +
            " .. )"
          );
        }
      }
      return this.$t("MESSAGES.TYPE.message");
    },
    isEmailOTP() {
      return (
        this.information.authentication_method == "link" ||
        this.information.authentication_method == "email-otp"
      );
    },
    showCloseBtn() {
      if (this.information.authentication_method == "link-sms") {
        return false;
      }
      if (this.information.authentication_method == "link") {
        return false;
      } else {
        return true;
      }
    },
    showReplyBtn() {
      if (this.information.message.status != "ARCHIVED") {
        return (
          this.IsMessage(this.information.message.message_type) &&
          this.information.message.settings.require_response == 1
        );
      } else {
        return false;
      }
    },
    showReplyKey() {
      return "replyKey" + this.showReplyIndex;
    },
    organisationImage() {
      return (
        this.user.hostname +
        "/organisation/image/" +
        this.information.organisation_id +
        "?uuid=" +
        this.information.organisation_image_uuid
      );
    },
    authMessage1() {
      if (this.information.type == "teams-meeting") {
        return this.$t("SECURELINK.TEAMS.NEED_TO_AUTHENTICATE");
      }
      if (this.information.type == "jitsi-meeting") {
        return this.$t("SECURELINK.JITSI.NEED_TO_AUTHENTICATE");
      }
      return this.$t("SECURELINK.NEED_TO_AUTHENTICATE");
    },
    authMessage2() {
      if (this.information.type == "teams-meeting") {
        return this.$t("SECURELINK.TEAMS.NEED_TO_AUTHENTICATE2");
      }
      if (this.information.type == "jitsi-meeting") {
        return this.$t("SECURELINK.JITSI.NEED_TO_AUTHENTICATE2");
      }
      return this.$t("SECURELINK.NEED_TO_AUTHENTICATE2");
    },
    createdTime() {
      return this.FormatDateTime(this.information.created_date);
    },
    createdName() {
      return (
        this.information.message.creator.name +
        ", " +
        this.information.message.creator.organisation_name
      );
    },
    authenticationImage() {
      return "/img/auth/" + this.information.authentication_method + ".png";
    },
  },
  mounted: function () {
    if (this.auth_uuid != undefined) {
      this.verifyAuthentication();
    } else {
      this.init();
    }
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
