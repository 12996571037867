<template>
  <section>
    
    <b-overlay :show="loading" rounded="sm">
        <button
          v-on:click="loginSubmit"
          class="btn btn-fill mt-2 btn-block btn-primary"
        >
          <img class="authIcon" src="/img/auth/net-id-enterprise.png" :alt="$t('AUTH_METHODS.net-id-enterprise')"  /> {{ $t("START") }} {{ $t('AUTH_METHODS.net-id-enterprise') }}
        </button>

    </b-overlay>
  </section>
</template>
<script>
export default {
  props: ["link_uuid"],
  data() {
    return {
      loading: true,
      auth_uuid: "",
      errorMessage: "",
    };
  },
  methods: {
    loginSubmit: async function(event) {
      event.preventDefault();
      this.loading = true;
      let self = this;
      this.$http
         .post(this.user.hostname + "/message/link/authenticate", {
           link_uuid: this.link_uuid,
           method: "net-id-enterprise"
         })
        .then(function(result) {          
          if(result.data.redirect == 1)
          {
            self.redirect = true;
            window.location.href = result.data.request_url;
          }else{
            self.auth_uuid = result.data.auth_uuid;
            self.makeRequest(result.data.request_url);
            self.$emit('setaccess', false);
          }
        })
        .catch(function() {
          self.$emit("loading", false);
          self.$emit('setaccess', true);
          self.loading = false;
        });
    },
    makeRequest: function(url) {
      this.loading = true;
      let self = this;
      this.$http
        .get(url)
        .then(function() {
          self.verifyAuthentication();
        })
        .catch(function() {
          self.loading = false;
          self.$emit('setaccess', true);
        });
    },
    verifyAuthentication: function() {
      let self = this;
      this.$http
        .get(this.user.hostname + "/authenticate/result/" + this.auth_uuid)
        .then(function(result) {
          self.$emit("authenticated", result.data.token);
        })
        .catch(function() {
          self.$emit('setaccess', true);
          self.$emit("loading", false);
          self.loading = false;
        });
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted: function() {
    this.loading = false;
  },
};
</script>
<style></style>
